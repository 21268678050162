<div class="ap-w-full ap-h-full ap-flex ap-justify-center ap-items-end">
  <div
    class="ap-bg-gray-200 ap-shadow-inner ap-p-5 ap-rounded-xl ap-w-full ap-max-w-[600px]"
  >
    <div>
      <p class="ap-text-xl ap-font-semibold ap-text-[#344054] ap-mb-2">
        Cookie Settings
      </p>
      <p class="ap-text-sm ap-text-[#344054] ap-leading-6 ap-mb-5">
        We use cookies to enhance your experience and analyze site traffic.
        <span>
          Read our
          <button
            href="/cookie-policy"
            target="_blank"
            class="ap-text-[#175cd3]"
          >
            Cookie Policy
          </button>
        </span>
      </p>
    </div>

    <div class="ap-flex ap-flex-col ap-gap-3 ap-mt-5">
      <div
        class="ap-shadow-inner ap-bg-gray-100 ap-rounded-lg ap-p-3 ap-cursor-pointer"
      >
        <div class="ap-flex ap-justify-between">
          <p class="ap-text-sm ap-font-semibold ap-text-[#344054]">Necessary</p>
          <p class="ap-text-sm ap-text-black">Always active</p>
        </div>
        <p class="ap-text-sm ap-text-[#344054] ap-mt-2">
          Enables security and basic functionality.
        </p>
      </div>

      <div
        class="ap-shadow-inner ap-bg-gray-100 ap-rounded-lg ap-p-3 ap-cursor-pointer"
        (click)="toggleCookie('preferences')"
      >
        <div class="ap-flex ap-justify-between">
          <p class="ap-text-sm ap-font-semibold ap-text-[#344054]">
            Preferences
          </p>
          <mat-slide-toggle
            [checked]="dataCookies.preferences"
            color="primary"
            [class.ap-cursor-not-allowed]=""
          ></mat-slide-toggle>
        </div>
      </div>

      <div
        class="ap-shadow-inner ap-bg-gray-100 ap-rounded-lg ap-p-3 ap-cursor-pointer"
        (click)="toggleCookie('analytics')"
      >
        <div class="ap-flex ap-justify-between">
          <p class="ap-text-sm ap-font-semibold ap-text-[#344054]">Analytics</p>
          <mat-slide-toggle
            [checked]="dataCookies.analytics"
            color="primary"
            [class.ap-cursor-not-allowed]=""
          ></mat-slide-toggle>
        </div>
      </div>

      <div
        class="ap-shadow-inner ap-bg-gray-100 ap-rounded-lg ap-p-3 ap-cursor-pointer"
        (click)="toggleCookie('marketing')"
      >
        <div class="ap-flex ap-justify-between">
          <p class="ap-text-sm ap-font-semibold ap-text-[#344054]">Marketing</p>
          <mat-slide-toggle
            [checked]="dataCookies.marketing"
            color="primary"
            [class.ap-cursor-not-allowed]=""
          ></mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="ap-flex ap-gap-2 ap-mt-4">
      <button
        class="ap-w-full ap-py-2 ap-px-4 ap-rounded-lg ap-bg-[#00ff81] ap-text-white"
        (click)="savePreferences()"
      >
        Save Preferences
      </button>
    </div>
  </div>
</div>
