import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service';
import {
  GetAllSubscriptionsType,
  PlansInfoApiType,
  SubscriptionCheckApiRequest,
} from '@upbrains/shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    public router: Router,
    private snackbarService: SnackbarService
  ) {}

  getUserInfo(): Observable<SubscriptionCheckApiRequest | null> {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .get<SubscriptionCheckApiRequest>(
          `${environment.igniteUrl}/workflow-apis/get-user-info`,
          { headers }
        )
        .pipe(
          catchError((error) => {
            // Log specific error details for better troubleshooting
            console.error('Failed to fetch user info:', {
              message: error.message,
              status: error.status,
              url: error.url,
            });

            // Displaying a more user-friendly message
            this.snackbarService.showError(
              'Unable to load user info. Please try again later.'
            );

            // Handle logout in case of specific error status codes (e.g., unauthorized)
            setTimeout(() => {
              this.auth.logout();
            }, 3000);

            return of(null);
          })
        );
    }

    return of(null);
  }

  getPlansInfo(): Observable<PlansInfoApiType | null> {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .get<PlansInfoApiType>(
          `${environment.igniteUrl}/workflow-apis/get-plans-info`,
          { headers }
        )
        .pipe(
          catchError((error) => {
            // Enhanced error logging with additional details
            console.error('Failed to fetch plans info:', {
              message: error.message,
              status: error.status,
              url: error.url,
            });

            // Informative user message with option to dismiss
            this.snackbarService.showError(
              'Unable to load plans info. Please try again later.'
            );

            setTimeout(() => {
              this.router.navigate(['/']);
            }, 3000);
            return of(null);
          })
        );
    }

    return of(null);
  }

  createSubscription(payload: {
    customerId: string | undefined | null;
    paymentMethodId: string | undefined | null;
    priceId: string | undefined | null;
    quantity: number | undefined | null;
    additional_credits: number | undefined | null;
    trial_period_days: number | undefined | null;
    identifier: string | undefined | null;
    addon_services: string | undefined | null;
    onboardingPackage: string | undefined | null;
  }): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .post<any>(
          `${environment.igniteUrl}/webhooks/create-subscription`,
          payload,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            console.error('Error creating subscription:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }

  getStripePublishableKey(): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .get<any>(`${environment.igniteUrl}/webhooks/config`, {
          headers,
        })
        .pipe(
          catchError((error) => {
            console.error('Error catching stripe publishable key:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }

  createCustomer(payload: { email: string }): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .post<any>(
          `${environment.igniteUrl}/webhooks/create-customer`,
          payload,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            console.error('Error creating customer:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }

  retryInvoiceWithNewPaymentMethod(payload: {
    customerId: string;
    paymentMethodId: string;
    invoiceId: string;
    identifier: string;
  }): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .post<any>(`${environment.igniteUrl}/webhooks/retry-invoice`, payload, {
          headers,
        })
        .pipe(
          catchError((error) => {
            console.error('Error creating customer:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }

  cancelSubscription(payload: { subscriptionId: string }): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .post<any>(
          `${environment.igniteUrl}/webhooks/cancel-subscription`,
          payload,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            this.snackbarService.showError(
              'There was a problem in canceling subscription!'
            );
            console.error('Error canceling the subscription:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }

  sendEmail(payload: {
    identifier: string;
    email: string;
    message?: string;
    name: string;
    send_now: boolean;
  }): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .post<any>(
          `${environment.igniteUrl}/service-apis/send-email`,
          payload,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            console.error('Error in contact me:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }

  updateSubscription(payload: {
    existing_seats_price_id?: string | null;
    new_additional_credits?: string | null;
    new_seats_price_id?: string | null;
    new_user_seats_quantity?: string | null;
    subscription_id?: string | null;
  }): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .post<any>(
          `${environment.igniteUrl}/webhooks/update-subscription`,
          payload,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            console.error('Error in updating the subscription:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }

  getAllSubscriptions(): Observable<GetAllSubscriptionsType | null> {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .get<GetAllSubscriptionsType>(
          `${environment.igniteUrl}/service-apis/all-subscriptions`,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            console.error('Error in getting all subscriptions: ', error);
            return of(null);
          })
        );
    }

    return of(null);
  }
}
