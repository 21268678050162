import { Static, Type } from '@sinclair/typebox'
import { PaginatedResult } from '../../search-engine'
import { CaseIdentifier } from './case-identifiers'


export const CaseItemDocumentAllFields = Type.Object({
    _id: Type.String(),
    caseId: Type.String(),
    caseName: Type.String(),
    caseSummary: Type.String(),
    caseType: Type.String(),
    creationTimestamp: Type.Date(),
    functionIds: Type.Array(Type.String()),
    identifiers: Type.Array(CaseIdentifier),
    inboxIds: Type.Array(Type.String()),
    lastUpdateTimestamp: Type.Date(),
    projectId: Type.String(),
    status: Type.String(),
    teamId: Type.String(),
    vectorField: Type.Unknown(),
})
export const CaseItemDocumentPartialFields = Type.Intersect([
    Type.Partial(CaseItemDocumentAllFields), 
    Type.Pick(CaseItemDocumentAllFields, [
        '_id',
        'caseId',
        'projectId',
        'teamId',
        'caseName',
        'creationTimestamp',
        'lastUpdateTimestamp',
    ]),
])
export type CaseItemDocumentAllFields = Static<typeof CaseItemDocumentAllFields>
export type CaseItemDocumentPartialFields = Static<typeof CaseItemDocumentPartialFields>

export const CaseFunction = Type.Object({
    caseFunctionId: Type.String(),
    caseFunctionName: Type.String(),
})
export type CaseFunction = Static<typeof CaseFunction>

export const CaseItem = Type.Object({
    projectId: Type.String(),
    caseId: Type.String(),
    teamId: Type.String(),
    caseName: Type.String(),
    caseType: Type.String(),
    status: Type.String(),
    creationTimestamp: Type.Date(),
    inboxIds: Type.Array(Type.String()),
    lastUpdateTimestamp: Type.Date(),
    caseFunctions: Type.Array(CaseFunction),
    identifiers: Type.Array(CaseIdentifier),
})

export const PaginatedCaseItems = PaginatedResult(CaseItem)
export const PaginatedCaseItemDocumentAllFields = PaginatedResult(CaseItemDocumentAllFields)  

export type CaseItem = Static<typeof CaseItem>

export type PaginatedCaseItems = Static<typeof PaginatedCaseItems>
export type PaginatedCaseItemDocumentAllFields = Static<typeof PaginatedCaseItemDocumentAllFields>