<div
  class="ap-w-full ap-bg-[#FEF3F2] ap-p-4 ap-border ap-border-[#D92D20] ap-rounded-lg"
>
  <div class="ap-flex ap-items-center ap-gap-3">
    <div class="ap-w-6 ap-h-6">
      <svg-icon
        src="assets/img/newDesign/info.svg"
        [svgStyle]="{ width: '24px', height: '24px' }"
      ></svg-icon>
    </div>

    <span class="ap-flex-grow ap-text-gray-600 ap-text-base">
      {{ message }}
    </span>

    <ng-container *ngIf="showButton">
      <div *ngIf="actionButton" class="ap-flex ap-items-center ap-gap-3">
        <ap-button
          btnSize="medium"
          btnColor="warn"
          (click)="onRetry()"
          [loading]="actionButton.loading"
          [disabled]="actionButton.loading"
        >
          {{ actionButton.label }}
        </ap-button>
      </div>
    </ng-container>
  </div>
</div>
