<ng-container *ngIf="agentsWithAuth$ | async as agentsWithAuth; else loading">
  <div
    class="ap-w-full ap-max-w-[892px] ap-max-h-full ap-overflow-auto ap-flex ap-flex-col ap-justify-center ap-items-center ap-mx-auto custom-agent-connection ap-mt-10 ap-mb-24"
  >
    <div
      class="ap-mx-auto ap-flex ap-flex-row ap-flex-wrap ap-gap-3 ap-mt-6 ap-max-w-full scroll-container"
      *ngIf="agentsWithAuth$ | async as agentsWithAuth"
    >
      <ng-container *ngFor="let agent of agentsWithAuth">
        <app-agent-connection-card
          [agent]="agent"
          [getConnections]="getConnectionAndUpdateAgents.bind(this)"
        />
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <ap-connection-skeleton />
</ng-template>
