import { Static, Type } from '@sinclair/typebox'
import { PaginatedResult } from '../../search-engine'

export const ConversationSummary = Type.Object({
    conversationId: Type.String(),
    projectId: Type.String(),
    conversationSummary: Type.String(),
})



export const BunchConversationSummary = Type.Array(ConversationSummary)

export const PaginatedBunchConversationSummary = PaginatedResult(BunchConversationSummary)

export type ConversationSummary = Static<typeof ConversationSummary>

export type BunchConversationSummary = Static<typeof BunchConversationSummary>

export type PaginatedBunchConversationSummary = Static<typeof PaginatedBunchConversationSummary>