import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PostPromptTemplateAPIType,
  PostPromptTemplatePayloadType,
} from '@upbrains/shared';
import {
  AddNewLineBusinessComponent,
  industryList,
  onboardingSelectors,
  OnboardingService,
} from '@upbrains/ui/common';
import { catchError, Observable, of, tap } from 'rxjs';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-extracted-insights',
  templateUrl: './extracted-insights.component.html',
  styleUrls: ['./extracted-insights.component.scss'],
})
export class ExtractedInsightsComponent implements OnInit, AfterViewInit {
  @Input() onNextStep!: () => void;
  @ViewChild('businessNameInput') businessNameInput!: ElementRef;
  @ViewChild('lineInput') lineInput!: ElementRef<HTMLInputElement>;
  industryList: string[] = industryList;
  lineItems: string[] = [];
  businessData$: Observable<{
    businessName: string | null;
    lineItems: string[] | undefined;
    description: string | undefined;
    selectedIndustry: string | null;
  }> = of({
    businessName: '',
    lineItems: [],
    description: '',
    selectedIndustry: '',
  });
  form: FormGroup<{
    businessName: FormControl<string | null>;
    description: FormControl<string | null>;
    selectedIndustry: FormControl<string | null>;
    lineItems: FormControl<string[] | null>;
  }>;
  isLoading = false;
  isManual = false;
  lineItemsError = false;
  showAddLineInput = false;
  inputWidth = 56;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      businessName: new FormControl<string | null>('', {
        validators: [Validators.required, Validators.minLength(3)],
      }),
      description: new FormControl<string | null>('', {
        validators: [Validators.required, Validators.minLength(3)],
      }),
      selectedIndustry: new FormControl<string | null>('', {
        validators: [Validators.required],
      }),
      lineItems: new FormControl<string[] | null>([], {
        validators: [Validators.required],
      }),
    });
  }

  ngOnInit(): void {
    this.businessData$ = this.store.select(
      onboardingSelectors.selectOnboardingBusinessData
    );
    this.businessData$.subscribe((businessData) => {
      this.form.controls.businessName.setValue(businessData.businessName ?? '');
      this.form.controls.description.setValue(businessData.description ?? '');
      this.form.controls.selectedIndustry.setValue(
        businessData.selectedIndustry ?? ''
      );
      this.form.controls.selectedIndustry.setValue(
        businessData.selectedIndustry ?? ''
      );
      this.form.controls.lineItems.setValue(businessData.lineItems ?? []);
    });

    this.isManual =
      this.route.snapshot.queryParamMap.get('isManual') === 'true';

    if (!this.isManual && !this.form.controls.description.value) {
      this.form.controls.description.setValue(
        `Business Name: ${this.form.controls.businessName.value}`
      );
    }
  }

  ngAfterViewInit(): void {
    this.businessNameInput.nativeElement.focus();
  }

  onNextButtonClick() {
    this.form.markAllAsTouched();
    if (
      this.form.valid &&
      this.form.controls.businessName.value &&
      this.form.controls.selectedIndustry.value &&
      this.form.controls.description.value &&
      this.form.controls.lineItems.value
    ) {
      this.isLoading = true;

      const payload: PostPromptTemplatePayloadType = {
        name: this.form.controls.businessName.value ?? '',
        industry: this.form.controls.selectedIndustry.value ?? '',
        description: this.form.controls.description.value ?? '',
        sectors: this.form.controls.lineItems.value ?? [],
      };

      this.onboardingService
        .postPromptTemplate(payload)
        .pipe(
          tap((response: PostPromptTemplateAPIType | null) => {
            if (response && response.status === 200) {
              this.isLoading = false;
              this.snackbar.open(
                'Prompt Template record was created successfully.',
                'Close',
                {
                  duration: 3000,
                }
              );
              this.onNextStep();
            } else {
              this.isLoading = false;
              this.cdr.detectChanges();
            }
          }),
          catchError((err) => {
            this.isLoading = false;
            console.error('Error posting data:', err);
            this.snackbar.open(
              'Prompt Template record was not created successfully',
              'Close',
              {
                duration: 3000,
              }
            );
            this.cdr.detectChanges();
            return of(null);
          })
        )
        .subscribe();
    }
  }

  removeItem(item: string): void {
    const currentLineItems = this.form.get('lineItems')?.value || [];

    const index = currentLineItems.indexOf(item);

    if (index >= 0) {
      const updatedLineItems = [
        ...currentLineItems.slice(0, index),
        ...currentLineItems.slice(index + 1),
      ];

      this.form.get('lineItems')?.setValue(updatedLineItems);

      this.form.get('lineItems')?.updateValueAndValidity();

      this.lineItemsError = updatedLineItems.length === 0;
    }
  }

  onAddNewLineBusiness() {
    return this.matDialog
      .open(AddNewLineBusinessComponent)
      .afterClosed()
      .pipe(
        tap((result: string) => {
          if (result) {
            const currentLineItems = this.form.get('lineItems')?.value || [];

            const updatedLineItems = [...currentLineItems, result];

            this.form.get('lineItems')?.setValue(updatedLineItems);

            this.lineItemsError = false;

            this.cdr.detectChanges();
          }
        })
      )
      .subscribe();
  }

  toggleAddLineInput(show: boolean) {
    this.showAddLineInput = show;
    if (show) {
      this.inputWidth = 56;
      setTimeout(() => {
        this.lineInput.nativeElement.focus();
        this.cdr.detectChanges();
      }, 0);
    }
  }

  addLineItem(value: string) {
    const trimmedValue = value.trim();

    if (trimmedValue) {
      const currentLineItems = this.form.get('lineItems')?.value || [];

      const updatedLineItems = [...currentLineItems, trimmedValue];

      this.form.get('lineItems')?.setValue(updatedLineItems);

      this.lineItemsError = false;

      this.showAddLineInput = false;
    }
  }

  adjustWidth(lineInput: HTMLInputElement): void {
    this.inputWidth = Math.max(56, 56 + lineInput.value.length * 8);
  }

  onEnterPressed(event: Event, lineInput: HTMLInputElement): void {
    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.stopPropagation();
    this.addLineItem(lineInput.value);
    lineInput.value = '';
  }
}
