import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuItem } from '@upbrains/shared';

@Component({
  selector: 'side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
})
export class SideMenuItemComponent implements OnInit {
  @Input() sideMenuItem: SideMenuItem;
  @Input() isCollapsed: boolean = false;
  @Input() isActive: boolean = false;
  @Input() actionRefData?: any;
  @Output() onSelectItem = new EventEmitter<void>();

  expandedItems: { [key: string]: boolean } = {};

  constructor(private router: Router) {}

  ngOnInit() {
    this.expandedItems['nestedItem'] = this.isActive;
  }

  toggleItemExpand(itemKey: string): void {
    this.expandedItems[itemKey] = !this.expandedItems[itemKey];
  }
  clickHandler(e: Event, data: SideMenuItem) {
    const clonedData = { ...data };
    if (this.actionRefData && !data.data) {
      clonedData.data = this.actionRefData;
    }
    data.click && data.click(e, this.actionRefData ? clonedData : data);
    this.navigateTo(data.route);
  }

  handleClickAndStyle(
    event: Event,
    sideMenuItem: any,
    nestedKey: string
  ): void {
    this.clickHandler(event, sideMenuItem);
    if (sideMenuItem.children.length) {
      this.toggleItemExpand(nestedKey);
    } else {
      this.navigateTo(sideMenuItem.route || '/agents');
    }
  }

  navigateTo(route: string): void {
    if (route) {
      this.onSelectItem.emit();
      this.router.navigate([route]);
    }
  }

  get fullPath(): string {
    return this.router.url;
  }
}
