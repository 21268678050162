import { Static, Type } from '@sinclair/typebox'

export const MessagePayloadParamDto = Type.Object({
    projectId: Type.String({}),
    messageId: Type.String({}),
})

export type MessagePayloadParamDto = Static<typeof MessagePayloadParamDto>

export const MessageAutomationUpdateParamDto = Type.Object({
    projectId: Type.String({}),
    messageId: Type.String({}),
})
export type MessageAutomationUpdateParamDto = Static<
    typeof MessageAutomationUpdateParamDto
>

export const MessageAutomationUpdateBodyDto = Type.Record(
    Type.String(),
    Type.Unknown(),
)
export type MessageAutomationUpdateBodyDto = Static<
    typeof MessageAutomationUpdateBodyDto
>
