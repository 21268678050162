import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ActionMenuType } from '@upbrains/shared';

@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
})
export class ActionsMenuComponent implements OnChanges {
  @Input() items: ActionMenuType[] = [];
  @Input() data: any;
  @ViewChild('menuRef') menuTemplateRef: TemplateRef<any>;
  @Input() menuInverseAlign?: boolean;

  currentContextMenu?: ActionMenuType;
  contextMenuIsShow?: boolean;

  private overlayRef?: OverlayRef;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges() {
    this.hideSubMenu();
  }

  clickHandler(event: Event, item: ActionMenuType) {
    if (item.items?.length) {
      event.stopPropagation();
      this.showSubMenu(event.currentTarget as HTMLElement, item);
    }
    item.click && item.click(event, { ...item, data: this.data });
  }

  showSubMenu(element: HTMLElement, data: ActionMenuType) {
    if (data?.items) {
      this.currentContextMenu = {
        ...data,
        items: data.items.map((item) => ({
          ...item,
          click: (e, itemData) => {
            if (item && item.click && itemData) {
              item.click(e, { ...itemData, data: this.data });
              this.hideSubMenu();
              element.classList.remove('general-actions__button--active');
            }
          },
        })),
      };
      this.overlayRef = this.createOverlay(element);
      this.overlayRef.attach(
        new TemplatePortal(this.menuTemplateRef, this.viewContainerRef)
      );
      element.classList.add('general-actions__button--active');

      setTimeout(() => {
        this.contextMenuIsShow = true;
        this.cdr.detectChanges();
      }, 0);
      this.overlayRef.backdropClick().subscribe(() => {
        this.hideSubMenu();
        element.classList.remove('general-actions__button--active');
      });
    }
  }

  hideSubMenu() {
    if (!this.currentContextMenu) return;
    this.contextMenuIsShow = false;
    setTimeout(() => {
      this.currentContextMenu = undefined;
      this.overlayRef?.detach();
    }, 300);
  }

  private createOverlay(element: HTMLElement) {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(element)
        .withPositions([
          {
            originX: this.menuInverseAlign ? 'start' : 'end',
            originY: 'bottom',
            overlayX: this.menuInverseAlign ? 'start' : 'end',
            overlayY: 'top',
          },
        ]),
    });
  }
}
