import { Static, Type } from '@sinclair/typebox'
import { ConversationIdentifier } from '../../conversation'
import { PaginatedResult } from '../../search-engine'


export const MessageItem = Type.Object({
    automationPayload: Type.Object({}),
    automationStatus: Type.String(),
    caseIds: Type.String(),
    channel: Type.String(),
    childConversationIds: Type.Array(Type.String()),
    conversationId: Type.String(),
    conversationType: Type.String(),
    creationTimeStamp: Type.Date(),
    eventPayloads: Type.Object({}),
    eventType: Type.String(),
    from: Type.String(),
    inboxId: Type.String(),
    lastUpdateTimeStamp: Type.Date(),
    messageBody: Type.String(),
    messageSummary: Type.String(),
    messageType: Type.String(),
    parentConversationId: Type.String(),
    projectId: Type.String(),
    subject: Type.String(),
    tags: Type.Array(Type.String()),
    teamId: Type.String(),
    identifiers: ConversationIdentifier,
    flowId: Type.String(),
    flowRunId: Type.String(),
    flowVersionId: Type.String(),
})


export type MessageItem = Static<typeof MessageItem>

export const PaginatedMessageItems = PaginatedResult(MessageItem)

export type PaginatedMessageItems = Static<typeof PaginatedMessageItems>
