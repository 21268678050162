import { createReducer, on } from '@ngrx/store';
import { casesActions } from './cases.action';
import { CasesState } from './cases-state.model';

export const casesInitialState: CasesState = {
  selectedCase: null,
  casesList: [],
};

export const casesReducer = createReducer(
  casesInitialState,
  on(casesActions.setSelectedCase, (state, { selectedCase }): CasesState => {
    return {
      ...state,
      selectedCase,
    };
  }),
  on(
    casesActions.setCasesList,
    (state, { casesList }): CasesState => ({
      ...state,
      casesList,
    })
  )
);
