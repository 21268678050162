<ul class="actions-menu">
  <li class="actions-menu__item" *ngFor="let item of items">
    <button
      class="actions-menu__button"
      type="button"
      (click)="clickHandler($event, item)"
      [attr.title]="item.tooltip"
    >
      <img alt="" />
      <svg-icon
        *ngIf="item.iconClassName || item.items?.length"
        [applyClass]="true"
        src="assets/img/newDesign/extractor/vertical.svg"
      >
      </svg-icon>
      {{ item.text }}
    </button>
  </li>

  <ng-template #menuRef
    ><ng-container *ngIf="currentContextMenu">
      <app-context-menu
        class="actions-menu__context-menu"
        [class.actions-menu__context-menu--show]="contextMenuIsShow"
        [items]="currentContextMenu.items"
      ></app-context-menu>
    </ng-container>
  </ng-template>
</ul>
