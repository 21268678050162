import { Static, Type } from '@sinclair/typebox'

export enum InboxAnalysisIntegrationName {
    FRONT = 'Front',
    ZENDESK = 'Zendesk',
    MICROSOFT = 'Microsoft',
}

export const PostInboxAnalysisRequest = Type.Object({
    inboxName: Type.String(),
    inboxDescription: Type.String(),
    integrationName: Type.Enum(InboxAnalysisIntegrationName),
    offlineProcessing: Type.Boolean(),
    integrationCredential: Type.Optional(Type.String()),
    maxConversationLimit: Type.Optional(Type.String()),
    inboxId: Type.Optional(Type.String()),
    startTime: Type.Optional(Type.String()),
    endTime: Type.Optional(Type.String()),
    projectId: Type.String(),
    connectionId: Type.String(),
    userId: Type.String(),
})

export const InboxAnalysisResponse = Type.Object({
    status: Type.Number(),
    detail: Type.String(),
    status_summary: Type.String(),
})

export type PostInboxAnalysisRequest = Static<typeof PostInboxAnalysisRequest>
export type InboxAnalysisResponse = Static<typeof InboxAnalysisResponse>
