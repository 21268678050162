<div class="ap-font-inter ap-flex ap-flex-col ap-p-10 cancel-subscriptions">
  <p class="ap-font-bold ap-text-2xl">
    Sorry you decided to leave us. Please tell us why?
  </p>

  <mat-form-field class="ap-w-full ap-mt-4">
    <mat-label>Select*</mat-label>
    <mat-select [(ngModel)]="selectedIssue">
      <!-- Ensure input.value is defined and a string before splitting -->
      <mat-option *ngFor="let issue of issuesList" [value]="issue" i18n>{{
        issue
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <p class="ap-font-normal ap-text-xs ap-mt-5">
    Sorry you decided to leave us. Please tell us why?
  </p>
  <mat-form-field appearance="outline" class="ap-w-full ap-mt-4">
    <mat-label>Comment</mat-label>
    <textarea
      matInput
      [(ngModel)]="message"
      name="description"
      placeholder="Enter your comment here"
      rows="4"
    ></textarea>
  </mat-form-field>
  <div
    class="ap-flex ap-justify-end ap-typography-caption ap-text-body ap-text-end"
  >
    <a (click)="contactMe()" class="!ap-no-underline ap-cursor-pointer" i18n
      >I actually need help. Please contact me!</a
    >
  </div>

  <div class="ap-grid ap-grid-cols-1 ap-gap-4 sm:ap-grid-cols-2 ap-mt-4">
    <ap-button
      actionButton
      (buttonClicked)="keepSubscription()"
      btnSize="small"
      [btnStyle]="'stroked'"
      i18n
      class="ap-w-full !ap-h-[33px]"
      [fullWidthOfContainer]="true"
    >
      <div
        class="ap-flex ap-items-center loading-template ap-text-sm ap-font-normal ap-rounded-2xl ap-font-inter"
      >
        Cancel
      </div>
    </ap-button>
    <ap-button
      actionButton
      (buttonClicked)="confirmCancel()"
      btnSize="small"
      btnColor="warn"
      i18n
      class="ap-w-full !ap-h-[33px]"
      [fullWidthOfContainer]="true"
      [disabled]="!selectedIssue"
    >
      <div
        class="ap-flex ap-items-center loading-template ap-text-sm ap-font-normal ap-rounded-2xl ap-font-inter"
      >
        <svg-icon
          *ngIf="data.isCanceling"
          src="assets/img/custom/loading.svg"
          class="loading-spin-animation white-loader"
          [applyClass]="true"
        >
        </svg-icon>
        Yes, proceed to cancel
      </div>
    </ap-button>
  </div>
</div>
