import { Subscription } from 'rxjs'
import { Static, Type } from '@sinclair/typebox'
import {
    ExtractorInfoType,
    ExtractorPagesType,
    ExtractorResultType,
    ProcessorResultType,
} from './extractor'

export const GoogleBucketFileResultType = Type.Object({
    results: Type.String(),
    status: Type.Number(),
})

export type GoogleBucketFileResultType = Static<
    typeof GoogleBucketFileResultType
>

export const GetExtractorResultQueryParamsType = Type.Object({
    file_name: Type.String(),
    processor_id: Type.String(),
})

export const GetExtractorResultType = Type.Object({
    id: Type.String(),
    processor_result: ProcessorResultType,
    processor_type: Type.String(),
    status: Type.Number(),
})

export type GetExtractorResultQueryParamsType = Static<
    typeof GetExtractorResultQueryParamsType
>
export type GetExtractorResultType = Static<typeof GetExtractorResultType>

export const PostExtractorQueryParamsType = Type.Object({
    extractor_id: Type.String(),
    save_in_db: Type.String(),
})

export const PostExtractorPayloadType = Type.Object({
    file: Type.Any(),
    extractor_id: Type.String(),
})

export const PostExtractorResultType = Type.Object({
    extractor_info: ExtractorInfoType,
    extractor_result: Type.Object({
        documents: Type.Array(ExtractorResultType),
        file_name: Type.String(),
        pages: Type.Array(ExtractorPagesType),
    }),
    result_id: Type.String(),
    status: Type.Number(),
})

export type PostExtractorQueryParamsType = Static<
    typeof PostExtractorQueryParamsType
>
export type PostExtractorPayloadType = Static<typeof PostExtractorPayloadType>
export type PostExtractorResultType = Static<typeof PostExtractorResultType>

export const SaveExtractorPayloadType = Type.Object({
    id: Type.String(),
    updated_extractor_result: Type.Object({
        documents: Type.Array(ExtractorResultType),
        file_name: Type.String(),
        pages: Type.Array(ExtractorPagesType),
    }),
})

export const SaveExtractorResultType = Type.Object({
    msg: Type.String(),
    status: Type.Number(),
})

export type SaveExtractorPayloadType = Static<typeof SaveExtractorPayloadType>
export type SaveExtractorResultType = Static<typeof SaveExtractorResultType>

export const PublishExtractorPayloadType = Type.Object({
    id: Type.String(),
})

export type PublishExtractorPayloadType = Static<
    typeof PublishExtractorPayloadType
>

export type ExtractorListResponse = {
    number_of_results: number
    results: ExtractorList[]
    status: number
}

export type ExtractorList = {
    auto_training?: boolean
    display_name?: string
    extraction_schema?: Extractionschema
    extractor_config?: Extractorconfig
    extractor_name?: string
    extractor_status?: string
    id?: string
    is_system_extractor?: boolean
    ai_model_id?: string
    model_name?: string
    model_type?: string
    only_file_processable?: boolean
    service_name?: string
    team_id?: number
    project_id?: string
}

export type StepWizard = {
    id: number
    title: string
    textButton: string
    isMandatory: boolean
    isCompleted: boolean
    isCurrentStep: boolean
    isClickEventNextButton: boolean
    component: string
}
export type DescriptionExtractorsFormModel = {
    name?: string | null
    documentClass?: string | null
    file?: string | null
    description?: string | null
}

export type ExtractorsFormModel = {
    descriptionExtractors?: DescriptionExtractorsFormModel
    definekeyExtractors?: ExtractorField
    definelineExtractors?: ExtractorLineitem
}
export enum ExtractorActionType {
    EDIT = 'edit',
    VIEW = 'view',
    CREATE = 'create',
    USE = 'use',
}

export type Extractorconfig = {
    pages: string
}

export type Extractionschema = {
    fields?: ExtractorField[]
    line_items?: ExtractorLineitem[]
    user_instruction?: string
    desired_class: string
}

export type ExtractorLineitem = {
    description: string
    name: string
    type: string
    addTolist?: boolean
}

export type ExtractorField = {
    description?: string
    many?: boolean
    name: string
    type?: string
    addTolist?: boolean
}

export type AttachmentResult = {
    fields: ExtractorField[]
    lineitem: ExtractorLineitem[] | null
}
//Attachment model

export type FileAttachmentType = {
    type: 'file'
    progress: number
    id?: string
    file: File
    subscription?: Subscription
}

export type AttachmentExtractorType = {
    title: string
    error?: string
    loading?: boolean
    subtitle?: string
} & FileAttachmentType

export type ExtractorInfo = {
    id: string
    model_id: string
    model_name: string
    service_name: string
    extractor_name: string
    only_file_processable: boolean
    display_name: string
}

export type FieldDetail = {
    content: string
    field_name: string
    page_number: string
    type: 'String' | 'Date' | 'Number'
    details: FieldDetails | null
}

export type FieldDetails = {
    confidenceScore?: number
    extractedBy?: string
    otherDetails?: string
}
export type DocumentField = Record<string, FieldDetail>

export type Document = {
    fields: DocumentField
    items: ExtractorLineitem[]
    content: string
}

export type Page = {
    page_number: number
    text: string
}

export type ExtractorResult = {
    file_name: string
    attachment_id: string | null
    document_type: string
    pages: Page[]
    documents: Document[]
    status: number
    extractor_info: ExtractorInfo
    extractor_result: ExtractorResult
    result_id: string
}

export type ExtractorResponse = {
    results: ExtractorInfo[]
}
export type UploadProgressEvent = {
    type: 'progress' | 'complete'
    percent?: number
    response?: ExtractorResult
}
