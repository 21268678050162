import { AuthenticationResponse } from '@upbrains/shared';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Base64 from 'js-base64';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication.service';
import { FlagService } from '../flag.service';

interface Params {
  [key: string]: string;
}
@Injectable({
  providedIn: 'root',
})
export class upbrainsAuthenticationService {
  constructor(
    private http: HttpClient,
    private mainAuth: AuthenticationService,
    private flagsService: FlagService
  ) {}

  private decodeBase64(encodedString: string): string {
    return Base64.decode(encodedString);
  }

  async authenticateUpBrainsUser(
    encodedUserEmail: string,
    encodedToken: string,
    params: Params
  ): Promise<boolean> {
    try {
      const userEmail = this.decodeBase64(encodedUserEmail);
      const userToken = this.decodeBase64(encodedToken);

      localStorage.removeItem('upbrainsUser');
      localStorage.removeItem(environment.jwtTokenName);
      localStorage.removeItem(environment.userPropertyNameInLocalStorage);
      localStorage.removeItem('isAdmin');
      this.flagsService.reinitialiseFlags();

      const user = (await this.http
        .post(environment.apiUrl + '/authentication/upbrains-authentication', {
          email: userEmail,
          token: userToken,
        })
        .toPromise()) as AuthenticationResponse;

      if (user.token) {
        this._saveUser(user);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('error', error);
      return false;
    }
  }

  private async _saveUser(user: AuthenticationResponse): Promise<void> {
    this.mainAuth.saveToken(user.token);
    this.mainAuth.updateUser(user);
  }

  upbrainsLogout(): void {
    localStorage.removeItem('upbrainsUser');
    localStorage.removeItem(environment.jwtTokenName);
    localStorage.removeItem(environment.userPropertyNameInLocalStorage);
    localStorage.removeItem('isAdmin');
    this.flagsService.reinitialiseFlags();
    window.location.href = `${environment.igniteUrl}/dashboard`;
  }
}
