import { Static, Type } from '@sinclair/typebox'


export const CaseIdentifier = Type.Object({
    identifierId: Type.String(),
    identifierName: Type.String(),
})


export type CaseIdentifier = Static<typeof CaseIdentifier>

