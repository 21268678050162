import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { AuthenticationService } from '../../service';
import { Store } from '@ngrx/store';
import { SubscriptionPlansSelectors } from '../../store';

@Component({
  selector: 'app-workflow-header',
  templateUrl: './workflow-header.component.html',
  styleUrls: ['./workflow-header.component.scss'],
})
export class WorkflowHeaderComponent implements OnInit {
  @Input({ required: true }) showProfile: boolean = false;
  @Input({ required: true }) profile: { email: string; name: string };
  @Input() isOnboarding: boolean = false;

  planName: string | undefined | null = '';

  constructor(
    private authenticationService: AuthenticationService,
    private elementRef: ElementRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.store
      .select(SubscriptionPlansSelectors.selectSubscriptionPlan)
      .subscribe((sub) => (this.planName = sub?.plan_name));
  }

  toggleProfile() {
    this.showProfile = !this.showProfile;
  }

  onProfileClick(event: MouseEvent) {
    event.stopPropagation();
  }

  logout() {
    this.authenticationService.logout();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const toggleButton =
      this.elementRef.nativeElement.querySelector('.ap-cursor-pointer');
    if (toggleButton) {
      const isInsideProfile = clickedElement.closest('.custom-profile');
      const isToggleButton = toggleButton.contains(clickedElement);

      if (!isInsideProfile && !isToggleButton && this.showProfile) {
        this.showProfile = false;
      }
    }
  }
}
