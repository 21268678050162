<div class="ap-inline-block" *ngIf="url">
  <div
    class="ap-rounded-full ap-border ap-border-solid ap-border-dividers ap-h-[35px] ap-w-[35px] ap-flex ap-items-center ap-justify-center ap-overflow-hidden"
  >
    <img [src]="url" class="ap-w-full ap-h-full ap-object-contain" />
  </div>
</div>

<div class="ap-inline-block ap-relative">
  <div
    *ngIf="moreIconsNumber > 0"
    class="ap-rounded-full ap-border ap-border-solid ap-border-dividers ap-h-[35px] ap-w-[35px] ap-flex ap-items-center ap-justify-center ap-ml-[5px] ap-overflow-hidden"
  >
    <img class="ap-w-full ap-h-full ap-object-contain ap-invisible" />
    <div class="ap-absolute">+{{ moreIconsNumber }}</div>
  </div>
</div>
