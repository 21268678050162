import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  showSuccess(message: string, duration: number = 5000): void {
    this.snackbar.open(message, '', {
      duration,
      panelClass: ['custom-success-snackbar'],
    });
  }

  showError(message: string, duration: number = 5000): void {
    this.snackbar.open(message, '', {
      duration,
      panelClass: ['custom-error-snackbar'],
    });
  }

  showMessage(
    message: string,
    duration: number = 5000,
    panelClass: string[] = []
  ): void {
    this.snackbar.open(message, '', {
      duration,
      panelClass,
    });
  }
}
