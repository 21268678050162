import { Property, PropertyType } from '@upbrains/agents-framework';
import {
  Attachment,
  EmailConfig,
  FileResponseInterface,
  FlowRun,
  FlowVersion,
  FormType,
  PopulatedFlow,
  SearchFieldInputs,
  StaticFormsData,
  TelemetryEventName,
  WebhookPauseMetadata,
} from '@upbrains/shared';
import {
  BuilderAutocompleteMentionsDropdownService,
  FlagService,
  FlowBuilderService,
  FlowService,
  TelemetryService,
  WebSocketService,
  environment,
} from '@upbrains/ui/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  Observer,
  Subject,
  Subscription,
  of,
  forkJoin,
} from 'rxjs';
import {
  tap,
  take,
  map,
  switchMap,
  distinctUntilChanged,
  catchError,
} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormResult, FormResultTypes, FormsService } from './forms.service';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { Store } from '@ngrx/store';
import {
  BuilderSelectors,
  FlowItemDetailsActions,
  StepRunResult,
} from '@upbrains/ui/feature-builder-store';
import { toJson } from './utils/toJson';
import { findConnectionName } from './utils/findConnectionName';

type Input = {
  displayName: string;
  required: boolean;
  description: string;
  type: InputTypes;
  value?: string | string[];
  uniqueKey: string;
  hide: boolean | string;
};

type ApprovalInput = Omit<Input, 'value'> & { value: string };

enum InputTypes {
  TEXT = 'text',
  FILE = 'file',
  TEXT_AREA = 'text_area',
  TOGGLE = 'toggle',
  DISPLAY_TEXT = 'display_text',
  SELECT = 'select',
  APPROVAL_ACTION = 'APPROVAL_ACTION',
  CUSTOM_ACTION = 'custom_action',
  SUBMIT_ACTION = 'submit_action',
}

type FormProps = {
  inputs: Input[];
  waitForResponse: boolean;
};

type FlowForm = {
  props: FormProps;
  name: FormType;
  action?: Action;
};

interface Action {
  name: string;
  valid: boolean;
  displayName: string;
  type: string;
  settings: any;
  nextAction?: Action;
}

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
})
export class FormsComponent implements OnInit, OnDestroy {
  fullLogoUrl$: Observable<string>;
  flow$: Observable<FlowVersion>;
  submitForm$: Observable<FormResult | undefined>;
  form: FormGroup;
  props: FormProps | null = null;
  formProps: FlowForm | null | false = null;
  textInputs: Input[] = [];
  fileInputs: Input[] = [];
  inputs: Input[] = [];
  loading = false;
  error: string | null = null;
  webhookUrl: string | null = null;
  title: string;
  flow: PopulatedFlow | null = null;
  markdownResponse: Subject<string | null> = new Subject<string | null>();
  PropertyType = PropertyType;
  hasApprovalOrDisapproval: boolean;
  approvalAction: Input | null = null;
  disapprovalAction: Input | null = null;
  submitAction: Input | null = null;
  isTrigger: boolean;
  formActionData: Action | null = null;
  formType: FormType;
  continueUrl = '';
  staticFormsData: StaticFormsData;
  approvalButtons: { approve: ApprovalInput; disapprove: ApprovalInput }[] = [];
  showSubmitButton = true;
  runId: string | undefined = '';
  mainEmailData: {
    formEmailConfig?: EmailConfig;
    formAttachments?: Attachment[];
  };
  selectedRun$: Observable<FlowRun | undefined> = of(undefined);
  links: { approvalLink?: string; disapprovalLink?: string } | null = {};
  logs$: Observable<
    | {
        runResults: StepRunResult[];
      }
    | undefined
    | null
  > | null = null;

  private logsData: StepRunResult[] = [];
  private logsSubscription: Subscription | null = null;

  currentStepResult$: Observable<
    Pick<StepRunResult, 'displayName' | 'output' | 'stepName'> | undefined
  >;
  runResults: StepRunResult[] = [];

  loadInitialData$: Observable<void> = new Observable<void>();

  previousSerializedApprovalButtons: any;
  searchFieldInputs: SearchFieldInputs;

  constructor(
    private flowService: FlowService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private flagService: FlagService,
    private formsService: FormsService,
    private telemteryService: TelemetryService,
    private router: Router,
    private http: HttpClient,
    private store: Store,
    private snackbar: MatSnackBar,
    public builderService: FlowBuilderService,
    public builderAutocompleteService: BuilderAutocompleteMentionsDropdownService,
    private websocketService: WebSocketService
  ) {
    this.fullLogoUrl$ = this.flagService
      .getLogos()
      .pipe(map((logos) => logos.fullLogoUrl));
  }

  ngOnInit(): void {
    this.websocketService.connect();
    this.store.dispatch(FlowItemDetailsActions.loadFlowItemsDetails());

    this.selectedRun$ = this.store.select(
      BuilderSelectors.selectCurrentFlowRun
    );
    this.logs$ = this.selectedRun$.pipe(
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify(prev) === JSON.stringify(curr);
      }),
      switchMap((flowRun) => {
        return this.store
          .select(BuilderSelectors.selectStepResultsAccordion)
          .pipe(
            take(1),
            map((results) => {
              this.runResults = results;
              return {
                runResults: this.runResults,
              };
            })
          );
      })
    );

    if (this.logs$) {
      this.logsSubscription = this.logs$.subscribe((data) => {
        this.logsData = data?.runResults || [];
      });
    }

    this.route.queryParams.subscribe((params) => {
      this.runId = params['runId'];
    });

    this.flow$ = this.route.paramMap.pipe(
      switchMap((params) =>
        this.flowService.get(params.get('flowId') as string)
      ),
      tap((flow) => {
        this.title = flow.version.displayName;
        this.form = new FormGroup({});

        this.telemteryService.capture({
          name: TelemetryEventName.FORMS_VIEWED,
          payload: {
            flowId: flow.id,
            formProps: this.props!,
            projectId: flow.projectId,
          },
        });
        this.flow = flow;
      }),
      switchMap((flow) => {
        {
          return of(flow.version);
        }
      }),
      tap((version) => {
        const serializeVersion = this.serializeFlowVersion(version);
        this.formProps = this.doesFlowHaveForm(serializeVersion);
        if (this.formProps) {
          this.formType = this.formProps.name;

          this.webhookUrl = environment.apiUrl + '/webhooks/' + this.flow!.id;
          this.http.post(this.webhookUrl, {});

          if (this.formProps?.props.waitForResponse) {
            this.webhookUrl += '/sync';
          } else if (
            this.formType === 'rfq_form' ||
            this.formType === 'document_review' ||
            this.formType === 'order_entry'
          ) {
            let spreadsheetLogData: SearchFieldInputs | undefined;
            let stepName: string | undefined;
            let connectionName: string | undefined;
            if (this.logsData?.length) {
              const _spreadsheetLogData = this.logsData.find((logData) => {
                return logData?.output?.input?.['spreadsheet_id'];
              });

              spreadsheetLogData = _spreadsheetLogData
                ? (_spreadsheetLogData?.output?.input as SearchFieldInputs)
                : undefined;

              stepName = this.logsData.find((logData) => {
                return logData?.output?.input?.['spreadsheet_id'];
              })?.stepName;
            }

            if (stepName && serializeVersion?.trigger && spreadsheetLogData) {
              connectionName = findConnectionName(
                stepName,
                serializeVersion.trigger
              );
              spreadsheetLogData = {
                ...spreadsheetLogData,
                ...{ connectionName },
              };
            }
            this.title = this.formProps.props?.['formName'];
            this.staticFormsData = {
              formData:
                typeof this.formProps.props?.['data'] === 'string'
                  ? JSON.parse(this.formProps.props?.['data'])
                  : this.formProps.props?.['data'],
              formFileUrl: this.formProps.props?.['fileUrl'],
              formText: this.formProps.props?.['text'],
              formSearchType: this.formProps?.props?.['searchType'],
              formFieldsOrder:
                toJson(this.formProps.props?.['fieldsOrder']) || undefined,
              spreadsheetLogData: spreadsheetLogData,
              formEmailConfig: this.formProps.props?.['hasEmail']
                ? this.formProps.props?.['emailConfig']
                : undefined,
              formAttachments:
                this.formProps?.props?.['attachments'] &&
                this.formProps?.props?.['attachments'],
              hasJsonButton: this.formProps?.props?.['hasJsonButton'] ?? false,
              multipleFiles: this.formProps?.props?.['multiplefiles'],
            };
            this.continueUrl =
              this.formActionData?.settings?.inputUiInfo?.currentSelectedData?.[
                'continueUrl'
              ] || '';
          }
          switch (this.formProps?.name) {
            case 'form-builder':
              this.showSubmitButton = this.formProps.props?.['hasSubmitButton'];
              this.buildInputs(this.formProps.props.inputs);
              break;

            case 'form_submission':
              this.buildInputs(this.formProps!.props.inputs);
              break;
            case 'file_submission':
              this.buildInputs([
                {
                  displayName: 'File',
                  description: 'File to submit.',
                  required: true,
                  type: InputTypes.FILE,
                  uniqueKey: 'file',
                  hide: false,
                },
              ]);
              break;
          }
        } else {
          this.formProps = null;
          this.error = 'This flow does not have a form.';
        }
        this.hasApprovalOrDisapproval = this.checkApprovalOrDisapprovalAction();
        this.findAndSetSubmitlAction();
      }),
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/']);
        throw err;
      })
    );
  }

  ngOnDestroy() {
    if (this.logsSubscription) {
      this.logsSubscription.unsubscribe();
    }
    this.websocketService.disconnect();
    this.snackbar.dismiss();
    this.builderService.componentToShowInsidePortal$.next(undefined);
  }

  doesActionContainFormBuilder(action: any): FlowForm | null {
    if (!action) return null;

    const { settings, nextAction, onSuccessAction } = action;
    const { actionName, agentName } = settings;

    const validActionNames = [
      'form-builder',
      'rfq_form',
      'document_review',
      'order_entry',
    ];

    if (
      agentName === '@upbrains/agent-forms' &&
      validActionNames.includes(actionName)
    ) {
      return {
        props: settings.input,
        name: actionName,
        action: action,
      };
    }

    // Recursively check the next actions
    return this.doesActionContainFormBuilder(nextAction || onSuccessAction);
  }

  traverseActions(action: any): false | FlowForm {
    if (!action) return false;
    const nextAction = this.doesActionContainFormBuilder(action);
    if (nextAction?.props) {
      this.formActionData = nextAction.action as Action;
      return nextAction as FlowForm;
    }
    return this.traverseActions(action.nextAction);
  }

  doesFlowHaveForm(version: FlowVersion): FlowForm | null | false {
    const { agentName, triggerName, input } = version.trigger.settings;
    const validTriggerNames = ['form_submission', 'file_submission'];

    const triggerProps =
      agentName === '@upbrains/agent-forms' &&
      validTriggerNames.includes(triggerName) &&
      ({
        props: input,
        name: triggerName,
      } as FlowForm);
    if (triggerProps) {
      this.isTrigger = true;
      return triggerProps;
    } else {
      this.isTrigger = false;
      return this.traverseActions(version.trigger.nextAction);
    }
  }

  async triggerSubmit() {
    if (this.form.valid && !this.loading) {
      this.markdownResponse.next(null);
      this.loading = true;

      const observables: Observable<string>[] = [];
      for (const key in this.form.value) {
        const isFileInput = this.fileInputs.find(
          (input) => this.getInputKey(input.uniqueKey) === key
        );

        if (isFileInput && this.form.value[key]) {
          observables.push(this.toBase64(this.form.value[key]));
        } else {
          observables.push(of(this.form.value[key]));
        }
      }

      this.submitForm$ = forkJoin(observables).pipe(
        map((values) => {
          const formData = new FormData();
          for (let i = 0; i < values.length; i++) {
            const key = Object.keys(this.form.value)[i];
            formData.append(key, values[i]);
          }
          this.webhookUrl =
            !this.isTrigger && !this.webhookUrl?.includes('/sync')
              ? this.webhookUrl! + '/sync'
              : this.webhookUrl;
          return formData;
        }),
        switchMap((formData) =>
          this.formsService.submitForm(this.webhookUrl!, formData)
        ),
        tap((result: FormResult) => {
          this.telemteryService.capture({
            name: TelemetryEventName.FORMS_SUBMITTED,
            payload: {
              flowId: this.flow!.id,
              formProps: this.props!,
              projectId: this.flow!.projectId,
            },
          });
          if (result.type === FormResultTypes.MARKDOWN) {
            this.markdownResponse.next(result.value as string);
          } else if (result.type === FormResultTypes.FILE) {
            const link = document.createElement('a');
            // Your base64 string
            const fileBase = result.value as FileResponseInterface;
            link.download = fileBase.fileName;
            link.href = fileBase.base64Url;
            link.target = '_blank';
            link.click();
            // Clean up by revoking the object URL
            URL.revokeObjectURL(fileBase.base64Url);
          } else {
            this.snackBar.open(
              `Your submission was successfully received.`,
              '',
              {
                duration: 5000,
              }
            );
          }
          this.loading = false;
        }),
        catchError((error) => {
          if (error.status === 404) {
            this.snackBar.open(`Flow not found. Please publish the flow.`, '', {
              panelClass: 'error',
              duration: 5000,
            });
          } else {
            this.snackBar.open(`Flow failed to execute`, '', {
              panelClass: 'error',
              duration: 5000,
            });
          }
          this.loading = false;
          return of(void 0);
        })
      );
    }
  }
  async actionSubmit(flowRun: FlowRun) {
    const observables: Observable<string>[] = [];

    for (const key in this.form.value) {
      const isFileInput = this.fileInputs.find(
        (input) => this.getInputKey(input.uniqueKey) === key
      );

      if (isFileInput && this.form.value[key]) {
        observables.push(this.toBase64(this.form.value[key]));
      } else {
        observables.push(of(this.form.value[key]));
      }
    }

    forkJoin(observables)
      .pipe(
        map((values) => {
          const formData = new FormData();
          for (let i = 0; i < values.length; i++) {
            const key = Object.keys(this.form.value)[i];
            formData.append(key, values[i]);
          }
          this.continueUrl =
            environment.apiUrl +
            '/flow-runs/' +
            this.runId +
            '/requests/' +
            (flowRun.pauseMetadata as WebhookPauseMetadata).requestId;
          return formData;
        }),
        switchMap((formData) => {
          return axios.post(this.continueUrl, formData);
        })
      )
      .subscribe({
        next: (response) => {
          this.router.navigate(['/action-center']);
        },
        error: (error) => {
          console.error('Form submission error', error);
        },
      });
  }

  async submit() {
    if (this.isTrigger) {
      this.triggerSubmit();
    } else {
      this.selectedRun$.pipe(take(1)).subscribe((flowRun) => {
        if (!flowRun) {
          console.error('No flow run found.');
          return;
        }
        this.actionSubmit(flowRun);
      });
    }
  }

  getInputKey(str: string) {
    return str
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
  }

  getUniqueInputKey(displayName: string, index: number): string {
    return `${displayName}_${index}`;
  }

  buildInputs(inputs: Input[]) {
    inputs.forEach((prop, index) => {
      let values: string[] = [];
      let defaultValue = '';
      // TODO: TEST THIS LINE
      if (prop.type === InputTypes.SELECT) {
        // Parse the JSON string to an object
        const parsedValue = JSON.parse(JSON.parse(prop?.value as string));
        // Get the first key of the object and extract the associated array
        const _values = parsedValue
          ? (Object.values(parsedValue)[0] as string[])
          : [];
        values = _values ? _values : [];
        defaultValue = _values ? _values[0] : '';
      }

      const uniqueKey = this.getInputKey(
        this.getUniqueInputKey(prop.displayName, index)
      );

      const hideValue =
        !prop.hide || prop.hide === 'false' || prop.hide === 'one-approval'
          ? false
          : true;
      const _prop: Input = { ...prop, hide: hideValue };

      switch (_prop.type) {
        case InputTypes.TEXT:
          if (typeof _prop.value !== 'object') {
            this.inputs.push(
              Property.ShortText({
                ..._prop,
                uniqueKey,
                value: _prop.value,
              }) as unknown as Input
            );
          }
          break;

        case InputTypes.FILE:
          this.inputs.push(
            Property.File({ ..._prop, uniqueKey }) as unknown as Input
          );
          break;

        case InputTypes.TEXT_AREA:
          if (typeof _prop.value !== 'object') {
            this.inputs.push(
              Property.LongText({
                ..._prop,
                uniqueKey,
                value: _prop.value,
              }) as unknown as Input
            );
          }
          break;

        case InputTypes.TOGGLE:
          this.inputs.push(
            Property.Checkbox({ ..._prop, uniqueKey }) as unknown as Input
          );
          break;

        case InputTypes.DISPLAY_TEXT:
          if (typeof _prop.value === 'string') {
            this.inputs.push(
              Property.DisplayText({
                ..._prop,
                uniqueKey,
                value: _prop.value,
              }) as unknown as Input
            );
          }
          break;

        case InputTypes.SELECT:
          this.inputs.push(
            Property.Select({
              ..._prop,
              value: values,
              uniqueKey,
            }) as unknown as Input
          );

          this.form.addControl(
            uniqueKey,
            new FormControl(defaultValue, { validators: [Validators.required] })
          );
          this.form.setValue({ ...this.form.value, [uniqueKey]: defaultValue });

          break;

        case InputTypes.SUBMIT_ACTION:
          if (typeof _prop.value === 'string') {
            this.inputs.push(
              Property.SubmitActionButton({
                ..._prop,
                uniqueKey,
                value: _prop.value,
              }) as unknown as Input
            );
          }
          break;

        case InputTypes.APPROVAL_ACTION:
          if (
            typeof _prop.value !== 'undefined' &&
            typeof _prop.value !== 'object'
          ) {
            this.inputs.push(
              Property.ApprovalActionButton({
                ..._prop,
                uniqueKey,
                value: _prop.value,
              }) as unknown as Input
            );
          }
          break;

        case InputTypes.CUSTOM_ACTION:
          if (
            typeof _prop.value !== 'undefined' &&
            typeof _prop.value !== 'object'
          ) {
            this.inputs.push(
              Property.CustomActionButton({
                ..._prop,
                uniqueKey,
                value: _prop.value,
              }) as unknown as Input
            );
          }
          break;
      }
      if (
        _prop.type !== InputTypes.DISPLAY_TEXT &&
        _prop.type !== InputTypes.SELECT
      ) {
        this.form.addControl(
          uniqueKey,
          new FormControl('', {
            nonNullable: _prop.required,
            validators: _prop.required ? [Validators.required] : [],
          })
        );
      }
    });
  }

  toBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };

      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }

  getOutputFromLogs(stepName: string): any {
    const step = this.logsData.find((log) => log.stepName === stepName);
    if (step?.output?.output) {
      return step?.output?.output;
    }
    return null;
  }

  resolveDynamicValue(dynamicValue: string): any {
    if (!this.logsData) return dynamicValue;

    const regex = /\{\{(.*?)\}\}/g;
    let match;
    let resolvedValue = dynamicValue;

    while ((match = regex.exec(dynamicValue)) !== null) {
      const path = match[1].split(/[\.\[\]\'\']+/).filter(Boolean);
      const stepName = path.shift();
      if (stepName) {
        const output = this.getOutputFromLogs(stepName);
        if (output) {
          let currentValue = output;
          for (const key of path) {
            if (currentValue && key in currentValue) {
              currentValue = currentValue[key];
            } else {
              currentValue = match[0]; // If the path is not found, use the original placeholder
              break;
            }
          }
          // Serialize the currentValue properly
          let stringValue;
          try {
            stringValue =
              typeof currentValue === 'object'
                ? JSON.stringify(currentValue)
                : currentValue.toString();
          } catch (error) {
            console.error('Error serializing value:', error);
            stringValue = match[0]; // Fallback to the original placeholder
          }

          resolvedValue = resolvedValue.replace(match[0], stringValue);
        }
      }
    }

    return resolvedValue;
  }

  replaceDynamicValues(action: any): void {
    if (!action) return;

    if (typeof action === 'object' && action !== null) {
      for (const key in action) {
        if (typeof action[key] === 'string') {
          action[key] = this.resolveDynamicValue(action[key]);
        } else if (typeof action[key] === 'object') {
          this.replaceDynamicValues(action[key]);
        }
      }
    }
  }

  serializeFlowVersion(flowVersion: FlowVersion): FlowVersion {
    this.replaceDynamicValues(flowVersion.trigger);
    return flowVersion;
  }

  openLinkInNewTab(url: string | undefined | string[]) {
    if (typeof url === 'string') window.open(url, '_blank');
  }

  checkApprovalOrDisapprovalAction() {
    this.inputs.forEach((input) => {
      if (input.type === InputTypes.APPROVAL_ACTION) {
        this.approvalButtons.push({
          approve: {
            ...(input as ApprovalInput),
            displayName: input.displayName,
          },
          disapprove: {
            ...(input as ApprovalInput),
            displayName: input.displayName,
          },
        });
      }
    });

    if (this.approvalButtons.length) {
      return true;
    } else {
      return false;
    }
  }
  findAndSetSubmitlAction() {
    this.submitAction = this.inputs.find(
      (i) => (i.type as string) === PropertyType.SUBMIT_ACTION
    ) as Input;
  }

  convertToString(value: string | string[] | undefined): string {
    if (typeof value === 'string') {
      return value;
    } else if (Array.isArray(value)) {
      // Join array values into a single string
      return value.join(', '); // Or any other appropriate format
    } else {
      return ''; // Handle undefined or other cases as needed
    }
  }
  getOptions(values: string[] | string | undefined): string[] {
    if (!values?.length && typeof values === 'string' && values === undefined) {
      return []; // Return empty array if value is undefined or falsy
    }
    // Split the value by '-' and trim each option
    return values as string[];
  }
}
