import { Static, Type } from '@sinclair/typebox'

export const CaseHighlightsParamDto = Type.Object({
    projectId: Type.String(),
    caseId: Type.String(),
})

export type CaseHighlightsParamDto = Static<typeof CaseHighlightsParamDto>


    