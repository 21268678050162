<div
  class="ap-w-[666px] ap-h-[381px] ap-flex ap-flex-col ap-gap-8 ap-items-center ap-justify-center ap-p-6 ap-bg-white ap-rounded-xl"
  [ngClass]="{ 'custom-upload-file': noModal }"
>
  <div class="ap-text-[#101828] ap-text-lg ap-font-semibold ap-font-inter">
    Import New File
  </div>
  <div
    class="ap-w-[444px] ap-rounded-2xl ap-flex ap-flex-col ap-gap-1 ap-items-center ap-justify-center ap-px-[7px] ap-py-[26px] ap-border ap-border-dashed ap-border-[#B8C1CC]"
  >
    <svg-icon
      class="ap-w-[40px] ap-h-[40px]"
      [applyClass]="true"
      src="assets/img/newDesign/extractor/cloud-upload.svg"
    >
    </svg-icon>

    <div
      class="ap-max-w-[200px] ap-text-[10px] ap-font-bold ap-font-inter ap-text-[#757575] ap-text-center"
    >
      Upload your flow file, drag and drop here or select from below button
    </div>
    <button
      class="ap-border ap-border-[#B8C1CC] ap-py-2 ap-px-6 ap-flex ap-flex-row ap-justify-center ap-items-center ap-text-xs ap-font-bold ap-font-inter ap-text-[#757575] ap-rounded-lg"
      (click)="browsFile()"
    >
      Browse File
    </button>

    <!-- Hidden File Input -->
    <input
      type="file"
      #fileInput
      class="ap-hidden"
      (change)="onFileSelected($event)"
      accept=".jpeg,.jpg,.png,.bmp,.pdf,.tiff,.txt"
    />
  </div>
</div>
