import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
/**
 * appValidateInput
 * Ensure no code, URL, html/json data is entered into any fields in sign up and sign in forms
 */

@Directive({
  selector: '[appValidateInput]',
})
export class ValidateInputDirective {
  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any): void {
    const invalidPattern =
      /[<>{}[\]\\;:"'`~!@#$%^&*()+=|?.,/\\]+|https?:|[{}[\]]/g;
    const inputValue = event.target.value;

    if (invalidPattern.test(inputValue)) {
      const cleanedValue = inputValue.replace(invalidPattern, '');
      this.control.control?.setValue(cleanedValue);
    }
  }
}
