import { Component, Input, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AllUserSubscriptionsType,
  PlansInfoObjectType,
  SubscriptionCheckRequest,
  SubscriptionPlansType,
} from '@upbrains/shared';
import { Observable } from 'rxjs';
import {
  setSubscriptionPlanInfo,
  SubscriptionPlansSelectors,
  UpbrainsUserSelectors,
} from '../../store';
import { AuthenticationService } from '../../service';
import * as CryptoJS from 'crypto-js';

const initialPlanFeatures = {
  starter: [
    {
      description: 'Free Copilot Credits (per seat / month): 1500',
      status: true,
    },
    { description: 'Standard Support (Email)', status: true },
    { description: 'Automated AI agents with workflows', status: false },
  ],
  professional: [
    {
      description: 'Free Copilot Credits (per seat / month): 3000',
      status: true,
    },
    { description: 'Standard Support (Email)', status: true },
    {
      description: '10 Automated AI agents with workflows',
      status: true,
    },
  ],
  premium: [
    {
      description: 'Free Copilot Credits (per seat / month): 5000',
      status: true,
    },
    { description: 'Premium Support (Email)', status: true },
    {
      description: '25 Automated AI agents with workflows',
      status: true,
    },
  ],
  enterprise: [
    { description: 'Custom Workflows', status: true },
    { description: 'Custom Trained AI Models', status: true },
    { description: 'Dedicated Onboarding and Training', status: true },
    { description: 'Premium Support', status: true },
  ],
};

type UpgradeButtonProperties = {
  content: string;
  bgColor: 'primary' | 'warn' | 'success' | 'basic' | 'white';
  border: 'flat' | 'basic' | 'raised' | 'stroked';
};

type InitialUpgradeButton = {
  starter: UpgradeButtonProperties;
  professional: UpgradeButtonProperties;
  premium: UpgradeButtonProperties;
  enterprise: UpgradeButtonProperties;
};

@Component({
  selector: 'ap-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  @Input() planData: PlansInfoObjectType | undefined | null;
  @Input() packagesList: PlansInfoObjectType[] | undefined | null;
  @Input() monthYearStatus: string;
  @Input() subscriptionName: string | null;
  @Input() onNextStep?: () => void;
  @Input() allUserSubscriptions?:
    | AllUserSubscriptionsType[]
    | undefined
    | null = undefined;

  initialPlanData = {
    billing_period: 'monthly',
    fixed_amount: 1,
    plan_name: 'Starter Plan',
    stripe_price_id: '1',
    subscription_credit_limit: 500000,
    users_limit: 20,
    subscription_quantity: 1,
    subscription_credit: 0,
    excess_unit_price: 1,
  };

  initialPlanPackages: {
    starter: {
      title: string;
      value: number | undefined | null;
      isSelected: boolean;
      stripeId: string | undefined | null;
    }[];
    professional: {
      title: string;
      value: number | undefined | null;
      isSelected: boolean;
      stripeId: string | undefined | null;
    }[];
    premium: {
      title: string;
      value: number | undefined | null;
      isSelected: boolean;
      stripeId: string | undefined | null;
    }[];
  };

  initialUpgradeButton: InitialUpgradeButton = {
    starter: {
      content: 'Get Started for Free',
      bgColor: 'basic',
      border: 'stroked',
    },
    professional: {
      content: 'Get Started for Free',
      bgColor: 'basic',
      border: 'stroked',
    },
    premium: {
      content: 'Get Started for Free',
      bgColor: 'basic',
      border: 'stroked',
    },
    enterprise: {
      content: 'Contact Sales',
      bgColor: 'basic',
      border: 'stroked',
    },
  };

  selectedPackageValue: number | undefined = 0;
  planButtonContent: string = '';
  totalPrice: number | undefined = 0;
  currentPlan: string | undefined | null = '';
  planFeatures: { description: string; status: boolean }[];
  planPackages: {
    title: string;
    value: number | undefined | null;
    stripeId: string | undefined | null;
    isSelected: boolean;
  }[];
  subscription$: Observable<SubscriptionCheckRequest | undefined | null>;
  hasSubscription: boolean = false;
  selectedPackageStripeId: string | undefined | null = '';
  subscriptionPlan$: Observable<SubscriptionPlansType | undefined>;
  subscriptionPeriod: number | undefined | null;
  subscriptionId: string | undefined | null;
  minUserSeats: number | null = 1;
  minCredits: number | null = 0;
  currentPlanStatus: string | undefined | null;
  subscriptionCheck: SubscriptionCheckRequest | undefined | null = {};

  constructor(
    private router: Router,
    private store: Store,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    // get current plan from store
    this.subscription$ = this.store.select(
      UpbrainsUserSelectors.selectUpbrainsUserSubscriptionCheck
    );

    this.subscription$.subscribe((subscriptionCheck) => {
      this.subscriptionCheck = subscriptionCheck;
      this.hasSubscription = subscriptionCheck?.status === 200;
    });
    // end

    this.initialPlanPackages = {
      starter: [
        {
          title: 'Self Serve ($0)',
          value: 0,
          isSelected: true,
          stripeId: null,
        },
      ],
      professional: [
        {
          title: 'Self Serve ($0)',
          value: 0,
          isSelected: false,
          stripeId: null,
        },
        {
          isSelected: true,
          ...this.findPackageValue('Standard'),
        },
        {
          isSelected: false,
          ...this.findPackageValue('Premium'),
        },
      ],
      premium: [
        {
          title: 'Self Serve ($0)',
          value: 0,
          isSelected: false,
          stripeId: null,
        },
        {
          isSelected: true,
          ...this.findPackageValue('Premium'),
        },
      ],
    };

    this.initialPlanData = {
      billing_period:
        this.planData?.billing_period ?? this.initialPlanData.billing_period,
      fixed_amount:
        this.planData?.fixed_amount ?? this.initialPlanData.fixed_amount,
      plan_name: this.planData?.name ?? this.initialPlanData.plan_name,
      stripe_price_id:
        this.planData?.stripe_price_id ?? this.initialPlanData.stripe_price_id,
      subscription_credit_limit:
        this.planData?.subscription_credit_limit ??
        this.initialPlanData.subscription_credit_limit,
      users_limit:
        this.planData?.users_limit ?? this.initialPlanData.users_limit,
      subscription_quantity: this.initialPlanData.subscription_quantity,
      subscription_credit: this.initialPlanData.subscription_credit,
      excess_unit_price:
        this.planData?.excess_unit_price ??
        this.initialPlanData.excess_unit_price,
    };

    this.store
      .select(SubscriptionPlansSelectors.selectSubscriptionPlan)
      .subscribe((subscriptionPlan) => {
        if (this.subscriptionCheck?.user_subscriptions?.length) {
          this.subscriptionCheck.user_subscriptions.map(
            ({ plan_name, subscription_status }) => {
              if (plan_name === subscriptionPlan?.plan_name) {
                this.currentPlanStatus = subscription_status;
              }
            }
          );
        }
        this.currentPlan =
          subscriptionPlan?.plan_name?.toLocaleLowerCase() ?? '';
        this.subscriptionPeriod = subscriptionPlan?.subscription_period;
        this.subscriptionId = subscriptionPlan?.subscription_id;
        this.minCredits =
          this.currentPlan &&
          this.initialPlanData.plan_name
            .toLocaleLowerCase()
            .includes(this.currentPlan) &&
          subscriptionPlan?.subscription_credit
            ? subscriptionPlan.subscription_credit
            : 0;
        this.minUserSeats =
          this.currentPlan &&
          this.initialPlanData.plan_name
            .toLocaleLowerCase()
            .includes(this.currentPlan) &&
          subscriptionPlan?.subscription_quantity
            ? subscriptionPlan.subscription_quantity
            : 1;
        this.initialPlanData = {
          ...this.initialPlanData,
          subscription_credit:
            this.currentPlan &&
            this.initialPlanData.plan_name
              .toLocaleLowerCase()
              .includes(this.currentPlan) &&
            subscriptionPlan?.subscription_credit
              ? subscriptionPlan?.subscription_credit
              : this.initialPlanData.subscription_credit,
          subscription_quantity:
            this.currentPlan &&
            this.initialPlanData.plan_name
              .toLocaleLowerCase()
              .includes(this.currentPlan) &&
            subscriptionPlan?.subscription_quantity
              ? subscriptionPlan?.subscription_quantity
              : this.initialPlanData.subscription_quantity,
        };
      });

    this.planFeatures = this.initialPlanData.plan_name
      .toLocaleLowerCase()
      .includes('starter')
      ? initialPlanFeatures['starter']
      : this.initialPlanData.plan_name
          .toLocaleLowerCase()
          .includes('professional')
      ? initialPlanFeatures['professional']
      : this.initialPlanData.plan_name.toLocaleLowerCase().includes('premium')
      ? initialPlanFeatures['premium']
      : initialPlanFeatures['enterprise'];

    this.planPackages = this.initialPlanData.plan_name
      .toLocaleLowerCase()
      .includes('starter')
      ? this.initialPlanPackages['starter']
      : this.initialPlanData.plan_name
          .toLocaleLowerCase()
          .includes('professional')
      ? this.initialPlanPackages['professional']
      : this.initialPlanData.plan_name.toLocaleLowerCase().includes('premium')
      ? this.initialPlanPackages['premium']
      : [];

    if (this.hasSubscription && !this.currentPlan?.includes('trial')) {
      if (this.currentPlan?.includes('starter')) {
        this.initialUpgradeButton.starter.content = 'Upgrade';
        this.initialUpgradeButton.professional.content = 'Upgrade';
        this.initialUpgradeButton.premium.content = 'Upgrade';
      } else if (this.currentPlan?.includes('professional')) {
        this.initialUpgradeButton.starter.content = '';
        this.initialUpgradeButton.professional.content = 'Upgrade';
        this.initialUpgradeButton.premium.content = 'Upgrade';
      } else if (this.currentPlan?.includes('premium')) {
        this.initialUpgradeButton.starter.content = '';
        this.initialUpgradeButton.professional.content = '';
        this.initialUpgradeButton.premium.content = 'Upgrade';
      }

      this.initialUpgradeButton.starter.bgColor = 'primary';
      this.initialUpgradeButton.professional.bgColor = 'primary';
      this.initialUpgradeButton.premium.bgColor = 'primary';

      this.initialUpgradeButton.starter.border = 'flat';
      this.initialUpgradeButton.professional.border = 'flat';
      this.initialUpgradeButton.premium.border = 'flat';
    } else {
      this.initialUpgradeButton.starter.content = 'Get Started';
      this.initialUpgradeButton.professional.content = 'Get Started';
      this.initialUpgradeButton.premium.content = 'Get Started';

      this.initialUpgradeButton.starter.bgColor = 'basic';
      this.initialUpgradeButton.professional.bgColor = 'basic';
      this.initialUpgradeButton.premium.bgColor = 'basic';

      this.initialUpgradeButton.starter.border = 'stroked';
      this.initialUpgradeButton.professional.border = 'stroked';
      this.initialUpgradeButton.premium.border = 'stroked';
    }

    this.calculateTheTotalPrice();
  }

  onUserSeatsInputChange(event: any) {
    const value = Number(event.target.value);
    if (value < 1) {
      this.initialPlanData.subscription_quantity = 1;
    } else if (value > this.initialPlanData.users_limit) {
      this.initialPlanData.subscription_quantity =
        this.initialPlanData.users_limit;
    } else {
      this.initialPlanData.subscription_quantity = value;
    }

    this.calculateTheTotalPrice();
  }

  onCreditsInputChange(event: any) {
    const value = Number(event.target.value);
    if (value < 0) {
      this.initialPlanData.subscription_credit = 0;
    } else if (value > this.initialPlanData.subscription_credit_limit) {
      this.initialPlanData.subscription_credit =
        this.initialPlanData.subscription_credit_limit;
    } else {
      this.initialPlanData.subscription_credit = value;
    }
    this.calculateTheTotalPrice();
  }

  calculateTheTotalPrice() {
    this.totalPrice =
      this.initialPlanData.subscription_quantity *
      this.initialPlanData.fixed_amount;

    if (this.monthYearStatus?.includes('month')) {
      this.totalPrice =
        this.initialPlanData.subscription_credit *
          this.initialPlanData.excess_unit_price +
        this.initialPlanData.subscription_quantity *
          this.initialPlanData.fixed_amount;
    } else {
      this.totalPrice =
        this.initialPlanData.subscription_credit *
          this.initialPlanData.excess_unit_price *
          12 *
          0.8 +
        this.initialPlanData.subscription_quantity *
          this.initialPlanData.fixed_amount;
    }
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  onSelectionChange(event: MatRadioChange) {
    this.selectedPackageValue = event.value;
    const selectedPackage = this.planPackages.find(
      (pkg) => pkg.value === event.value
    );
    this.selectedPackageStripeId = selectedPackage?.stripeId;
    this.calculateTheTotalPrice();
  }

  handleUpgrade() {
    const subscriptionPlan: Partial<SubscriptionPlansType> = {
      plan_name: this.initialPlanData.plan_name,
      fixed_amount: this.initialPlanData.fixed_amount,
      billing_period: this.initialPlanData.billing_period,
      subscription_total_price: this.totalPrice
        ? String(Number(this.totalPrice.toFixed(2)))
        : '',
      subscription_credit: this.initialPlanData.subscription_credit,
      subscription_quantity: this.initialPlanData.subscription_quantity,
      stripe_price_id: this.initialPlanData.stripe_price_id,
      onboarding_package_stripe_id: this.selectedPackageStripeId
        ? this.selectedPackageStripeId
        : '',
      should_update:
        this.subscriptionPeriod &&
        this.subscriptionPeriod > 0 &&
        this.findTheUpgradeButtonText() === 'Upgrade'
          ? true
          : false,
      subscription_id: this.subscriptionId,
      oneTimeCharge: this.selectedPackageValue,
      allUserSubscriptions: this.allUserSubscriptions,
    };

    const subscriptionPlanWithStatus = {
      ...subscriptionPlan,
      currentPlanStatus: this.currentPlanStatus,
    };

    const jsonString = JSON.stringify(subscriptionPlanWithStatus);
    const hashedPlan = CryptoJS.AES.encrypt(
      jsonString,
      this.auth.getDecodedIgniteToken()
    ).toString();

    if (this.onNextStep) {
      this.store.dispatch(setSubscriptionPlanInfo({ subscriptionPlan }));
      this.onNextStep();
    } else {
      this.router.navigate(
        [
          `/subscriptions/plans/${this.subscriptionName?.toLocaleLowerCase()}/subscribe`,
        ],
        {
          queryParams: { plan: hashedPlan },
        }
      );
    }
  }

  findTheUpgradeButtonText(): string {
    const plan = Object.keys(this.initialUpgradeButton).find((plan) => {
      return this.initialPlanData.plan_name.toLocaleLowerCase().includes(plan)
        ? plan
        : '';
    }) as keyof typeof this.initialUpgradeButton;

    if (plan) {
      return this.initialUpgradeButton[plan].content;
    }
    return '';
  }

  findTheUpgradeButtonBgColor():
    | 'primary'
    | 'warn'
    | 'success'
    | 'basic'
    | 'white' {
    const plan = Object.keys(this.initialUpgradeButton).find((plan) => {
      return this.initialPlanData.plan_name.toLocaleLowerCase().includes(plan)
        ? plan
        : '';
    }) as keyof typeof this.initialUpgradeButton;

    if (plan) {
      return this.initialUpgradeButton[plan].bgColor;
    }
    return 'basic';
  }

  findTheUpgradeButtonBorder(): 'flat' | 'basic' | 'raised' | 'stroked' {
    const plan = Object.keys(this.initialUpgradeButton).find((plan) => {
      return this.initialPlanData.plan_name.toLocaleLowerCase().includes(plan)
        ? plan
        : '';
    }) as keyof typeof this.initialUpgradeButton;

    if (plan) {
      return this.initialUpgradeButton[plan].border;
    }
    return 'stroked';
  }

  findPackageValue(title: string): {
    title: string;
    value: number | undefined | null;
    stripeId: string | undefined | null;
  } {
    const packageObject = this.packagesList?.find((pkg) =>
      pkg?.name?.toLocaleLowerCase()?.includes(title.toLocaleLowerCase())
    );
    if (packageObject) {
      return {
        title: `${title} Package ($${packageObject.fixed_amount})`,
        value: packageObject.fixed_amount,
        stripeId: packageObject.stripe_price_id,
      };
    }
    return {
      title: `${title} Package ($200)`,
      value: 200,
      stripeId: null,
    };
  }
}
