import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgentMetadataModelSummary } from '@upbrains/ui/common';
import { AddEditConnectionButtonComponent } from '../../add-edit-connection-button/add-edit-connection-button.component';

@Component({
  selector: 'app-connection-card',
  templateUrl: './connection-card.component.html',
})
export class ConnectionCardComponent {
  @ViewChildren('addConnectionBtn')
  addConnectionBtns!: QueryList<AddEditConnectionButtonComponent>;
  @Input() agent!: AgentMetadataModelSummary & { connected: boolean };
  @Input({ required: true }) getConnections!: () => void;

  constructor(private dialogService: MatDialog) {}

  connectAgent() {
    if (!this.agent.connected) {
      this.openNewConnectionDialog();
    }
  }

  private openNewConnectionDialog() {
    this.dialogService.closeAll();
    setTimeout(() => {
      const btn = this.addConnectionBtns.find(
        (button) => button.agentName === this.agent.name
      );
      if (btn) {
        btn.buttonClicked();

        if (btn.updateOrAddConnectionDialogClosed$) {
          btn.updateOrAddConnectionDialogClosed$.subscribe(() => {
            this.getConnections();
          });
        } else {
          console.error('updateOrAddConnectionDialogClosed$ is undefined');
        }
      } else {
        console.error('Button for this agent is not found');
      }
    });
  }
}
