import { Component, Input } from '@angular/core';
import { ActionMenuType } from '@upbrains/shared';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent {
  @Input() items?: ActionMenuType[] = [];
  @Input() actionRefData?: any;

  clickHandler(e: Event, data: ActionMenuType) {
    const clonedData = { ...data };
    if (this.actionRefData && !data.data) {
      clonedData.data = this.actionRefData;
    }
    data.click && data.click(e, this.actionRefData ? clonedData : data);
  }
}
