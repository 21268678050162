import { Static, Type } from '@sinclair/typebox'
import { SearchEngineSimpleFilters } from '../simple-filter-options'
import { SearchEngineSimpleSortOptions } from '../simple-sort-options'

export const SimpleQueryBodyDto = Type.Object({
    pageNumber: Type.Number(),
    pageSize: Type.Number(),
    searchValue: Type.Optional(Type.String()),
    filters: Type.Optional(SearchEngineSimpleFilters),
    sort: Type.Optional(SearchEngineSimpleSortOptions),
})
export type SimpleQueryBodyDto = Static<typeof SimpleQueryBodyDto>