<div class="ap-animate-pulse ap-bg-white ap-rounded-lg ap-p-4">
  <!-- Skeleton Header -->
  <div class="ap-flex ap-gap-4 ap-mb-4">
    <div class="ap-h-4 ap-bg-gray-300 ap-rounded ap-w-1/4"></div>
    <div class="ap-h-4 ap-bg-gray-300 ap-rounded ap-w-1/4"></div>
    <div class="ap-h-4 ap-bg-gray-300 ap-rounded ap-w-1/4"></div>
  </div>

  <!-- Skeleton Rows -->
  <div
    *ngFor="let i of [1, 2]"
    class="ap-flex ap-items-center ap-gap-4 ap-mb-4"
  >
    <div class="ap-w-8 ap-h-8 ap-bg-gray-300 ap-rounded-full"></div>
    <div class="ap-flex-1">
      <div class="ap-h-4 ap-bg-gray-300 ap-rounded ap-mb-2"></div>
      <div class="ap-h-4 ap-bg-gray-300 ap-rounded ap-w-3/4"></div>
    </div>
  </div>
</div>
