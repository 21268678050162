<div class="ap-bg-white ap-rounded-lg ap-p-4">
  <div class="left-panel ap-pl-7">
    <!-- Title Section -->
    <header class="ap-flex ap-items-center ap-my-5">
      <h1
        class="ap-text-blue-600 ap-text-3xl ap-font-semibold ap-font-['Inter'] ap-leading-9"
      >
        Adam Smith Order
      </h1>
    </header>
    <hr class="ap-border-b-2 ap-border-gray-100" />
    <!-- Search Box -->
    <div
      class="search-box ap-h-12 ap-px-5 ap-py-2.5 ap-bg-gray-100 ap-rounded-xl ap-inline-flex ap-items-center ap-gap-2.5 ap-my-3 ap-w-full"
    >
      <img
        src="/assets/img/custom/cases/search.svg"
        alt="Search Icon"
        class="ap-w-4 ap-h-4"
      />
      <input
        type="text"
        class="ap-cursor-pointer ap-opacity-40 ap-text-black ap-text-sm ap-font-normal ap-font-['Inter'] ap-leading-snug ap-outline-none ap-w-full ap-bg-gray-100"
        placeholder="Search..."
        [(ngModel)]="searchText"
        (input)="filterItems()"
      />
    </div>
    <!-- List Section -->
    <section class="list-section ap-cursor-pointer">
      <article
        *ngFor="let item of filteredItems"
        (click)="selectItem(item)"
        class="ap-w-80 ap-h-20 ap-p-3 ap-bg-white ap-rounded-xl ap-inline-flex ap-items-start ap-gap-4"
      >
        <div
          class="ap-w-12 ap-h-12 ap-bg-blue-100 ap-rounded-xl ap-flex ap-items-center ap-justify-center"
        >
          <img [src]="item.icon" alt="Icon" />
        </div>

        <div class="ap-flex-1 ap-flex ap-flex-col ap-gap-2">
          <h2
            class="ap-text-black ap-text-sm ap-font-bold ap-font-['Inter'] ap-leading-snug"
          >
            {{ item.title }}
          </h2>
          <p
            class="ap-text-gray-500 ap-text-xs ap-font-semibold ap-font-['Inter'] ap-leading-tight"
          >
            {{ item.description }}
          </p>
        </div>
      </article>
    </section>
  </div>
</div>
