import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  SubscriptionCheckRequest,
  SubscriptionPlansType,
} from '@upbrains/shared';
import { map, Observable } from 'rxjs';
import { setSubscriptionPlan, UpbrainsUserSelectors } from '../../store';
import { Router } from '@angular/router';
import { getLeftDaysOfSubscription } from '../../utils/utils';

@Component({
  selector: 'ap-subscription-banner',
  templateUrl: './subscription-banner.html',
  styleUrls: ['./subscription-banner.scss'],
})
export class SubscriptionBanner {
  @Input() showCurrentPlan: boolean = false;

  subscription$: Observable<SubscriptionCheckRequest | undefined | null>;
  planName: string | undefined | null = '';
  planDuration: number | undefined | null = 0;
  upgradeContent: string = '';
  subscriptionId: string | undefined | null;
  credits: number | undefined | null = 0;
  userSeats: number | undefined | null = 1;
  currentStatus: string | null | undefined;

  constructor(private store: Store, public router: Router) {
    this.subscription$ = this.store
      .select(UpbrainsUserSelectors.selectUpbrainsUserSubscriptionCheck)
      .pipe(
        map((subscriptionCheck) => {
          if (subscriptionCheck?.status === 200) {
            const activeOrTrialSubscription =
              subscriptionCheck?.user_subscriptions?.filter(
                ({ subscription_status }) =>
                  subscription_status === 'active' ||
                  subscription_status?.toLocaleLowerCase()?.includes('trial')
              )?.[0]; // Select the first item

            if (activeOrTrialSubscription) {
              const {
                subscription_renewal_date_timestamp,
                plan_name,
                subscription_status,
                subscription_quantity,
                stripe_subscription_id,
                additional_credits,
              } = activeOrTrialSubscription;

              this.currentStatus = subscription_status;
              const daysLeft = getLeftDaysOfSubscription(
                subscription_renewal_date_timestamp
              );

              if (subscription_status?.toLocaleLowerCase()?.includes('trial')) {
                this.upgradeContent = 'Upgrade';
                this.planName = `${plan_name} Plan`;
              } else if (daysLeft > 0) {
                this.planName = `${plan_name} Plan`;
                this.upgradeContent = 'Upgrade';
              } else {
                this.planName = 'No Subscription';
                this.upgradeContent = 'Upgrade';
              }

              this.planDuration = daysLeft;
              this.subscriptionId = stripe_subscription_id;
              this.credits = additional_credits;
              this.userSeats = subscription_quantity;
            } else {
              // Handle case where no active or trial subscription is found
              this.planName = 'No Subscription';
              this.upgradeContent = 'Upgrade';
              this.planDuration = 0;
              this.subscriptionId = null;
              this.credits = 0;
              this.userSeats = 0;
            }
          } else if (subscriptionCheck?.status === 401) {
            this.planName = 'No Subscription';
            this.upgradeContent = 'Get Started';
          }

          const subscriptionPlan: Partial<SubscriptionPlansType> = {
            subscription_period: this.planDuration,
            subscription_id: this.subscriptionId,
            subscription_quantity: this.userSeats,
            subscription_credit: this.credits,
            plan_name: this.planName?.replace(' Plan', ''),
          };

          this.store.dispatch(setSubscriptionPlan({ subscriptionPlan }));
          return subscriptionCheck;
        })
      );
  }

  handleSubscription() {
    this.router.navigate(['/subscriptions']);
  }

  handleUpgrade() {
    if (this.showCurrentPlan) {
      this.router.navigate([`/subscriptions`]);
    } else {
      this.router.navigate([`/subscriptions/plans/${this.planName}`]);
    }
  }

  shouldShowTrial(): string {
    return this.showCurrentPlan &&
      this.currentStatus &&
      (this.currentStatus.toLocaleLowerCase().includes('trial') ||
        this.currentStatus.toLocaleLowerCase().includes('trialing')) &&
      this.planName &&
      !this.planName.toLowerCase().includes('free trial')
      ? '- Free Trial'
      : '';
  }
}
