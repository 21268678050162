import { Injectable } from '@angular/core';
import {
  ActionType,
  ApEdition,
  AddAgentRequestBody,
  AgentOptionRequest,
  TriggerType,
  ApFlagId,
  AgentScope,
  ListAgentsRequestQuery,
  spreadIfDefined,
  A_MIN_TIMEOUT_IN_MILLISECONDS,
} from '@upbrains/shared';
import { HttpClient } from '@angular/common/http';
import {
  Observable,
  shareReplay,
  map,
  switchMap,
  Subject,
  combineLatest,
  startWith,
  take,
  timeout,
} from 'rxjs';
import semver from 'semver';
import {
  AuthenticationService,
  FlagService,
  environment,
} from '@upbrains/ui/common';
import { FlowItemDetails } from '@upbrains/ui/common';
import {
  DropdownState,
  AgentPropertyMap,
  TriggerBase,
  TriggerStrategy,
} from '@upbrains/agents-framework';
import { isNil } from '@upbrains/shared';
import {
  AgentMetadataModel,
  AgentMetadataModelSummary,
} from '@upbrains/ui/common';

type TriggersMetadata = Record<string, TriggerBase>;

export const CORE_AGENTS_ACTIONS_NAMES = [
  '@upbrains/agent-store',
  '@upbrains/agent-data-mapper',
  '@upbrains/agent-connections',
  '@upbrains/agent-delay',
  '@upbrains/agent-http',
  '@upbrains/agent-smtp',
  '@upbrains/agent-sftp',
  '@upbrains/agent-approval',
  '@upbrains/agent-tags',
  '@upbrains/agent-text-helper',
  '@upbrains/agent-date-helper',
  '@upbrains/agent-file-helper',
  '@upbrains/agent-math-helper',
];
export const coreAgentIconUrl = (agentName: string) =>
  `assets/img/newDesign/agents/${agentName.replace(
    '@upbrains/agent-',
    ''
  )}_mention.png`;
export const CORE_SCHEDULE = '@upbrains/agent-schedule';
export const CORE_AGENTS_TRIGGERS = [CORE_SCHEDULE];
@Injectable({
  providedIn: 'root',
})
export class AgentMetadataService {
  private release$ = this.flagsService.getRelease().pipe(shareReplay(1));
  private clearCache$ = new Subject<void>();
  private edition$ = this.flagsService.getEdition();
  private agentsManifest$ = this.getAgentsManifestFromServer({
    includeHidden: false,
  });
  private agentsCache = new Map<string, Observable<AgentMetadataModel>>();

  public coreFlowItemsDetails: FlowItemDetails[] = [
    // {
    //   type: ActionType.CODE,
    //   name: 'Code',
    //   description: 'Powerful nodejs & typescript code with npm',
    //   logoUrl: '/assets/img/newDesign/agents/code.svg',
    // },
    {
      type: ActionType.BRANCH,
      name: 'Binary Choice',
      description: 'Define conditions with a binary outcome',
      logoUrl: '/assets/img/newDesign/agents/binary-choice.svg',
    },
    {
      type: ActionType.LOOP_ON_ITEMS,
      name: 'Loop',
      description: 'Loop on a list of items',
      logoUrl: '/assets/img/newDesign/agents/loop.svg',
    },
    {
      type: ActionType.ROUTER,
      description: 'Split your flow into branches depending on condition(s)',
      name: 'Router',
      logoUrl: 'assets/img/newDesign/agents/router.svg',
    },
  ];

  public triggerItemsDetails: FlowItemDetails[] = [
    // {
    //   type: TriggerType.WEBHOOK,
    //   name: 'Webhook',
    //   description: 'Trigger flow by calling a unique web url',
    //   logoUrl: '/assets/img/newDesign/agents/webhook.svg',
    // },
    {
      type: TriggerType.EMPTY,
      name: 'Trigger',
      description: 'Choose a trigger',
      logoUrl: '/assets/img/newDesign/agents/empty-trigger.svg',
    },
  ];

  constructor(
    private http: HttpClient,
    private flagsService: FlagService,
    private authenticationService: AuthenticationService
  ) {}

  private getCacheKey(agentName: string, agentVersion: string): string {
    return `${agentName}-${agentVersion}`;
  }
  private filterAppWebhooks(
    agentName: string,
    triggersMap: TriggersMetadata,
    supportedApps: string[]
  ): TriggersMetadata {
    const triggersList = Object.entries(triggersMap);

    const filteredTriggersList = triggersList.filter(
      ([, trigger]) =>
        trigger.type !== TriggerStrategy.APP_WEBHOOK ||
        supportedApps.includes(agentName)
    );

    return Object.fromEntries(filteredTriggersList);
  }

  private fetchAgentMetadata({
    agentName,
    agentVersion,
    edition,
  }: {
    agentName: string;
    agentVersion: string;
    edition: ApEdition;
  }): Observable<AgentMetadataModel> {
    return this.http.get<AgentMetadataModel>(
      `${environment.apiUrl}/agents/${encodeURIComponent(agentName)}`,
      {
        params: {
          version: agentVersion,
          edition,
        },
      }
    );
  }

  installCommunityAgent(params: AddAgentParams) {
    const formData = new FormData();

    formData.set('packageType', params.packageType);
    formData.set('agentName', params.agentName);
    formData.set('agentVersion', params.agentVersion);
    formData.set('scope', params.scope);
    if (params.scope === AgentScope.PROJECT) {
      formData.set('projectId', this.authenticationService.getProjectId());
    }
    if (params.agentArchive) {
      formData.set('agentArchive', params.agentArchive);
    }

    return this.http.post<AgentMetadataModel>(
      `${environment.apiUrl}/agents`,
      formData
    );
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/agents/${id}`);
  }

  clearCache() {
    this.clearCache$.next();
  }

  getCommunityAgents(): Observable<AgentMetadataModelSummary[]> {
    return this.agentsManifest$.pipe(
      map((agents) => agents.filter((p) => !isNil(p.projectId)))
    );
  }

  getAgentsManifest(): Observable<AgentMetadataModelSummary[]> {
    return this.agentsManifest$.pipe(take(1));
  }

  getLatestVersion(agentName: string): Observable<string | undefined> {
    return this.getAgentsManifest().pipe(
      map((agents) => {
        const filteredAgents = agents.filter(
          (agent) => agent.name === agentName
        );
        if (filteredAgents.length === 0) {
          return undefined;
        }
        return filteredAgents
          .sort((a, b) => semver.compare(b.version, a.version))
          .map((agent) => agent.version)[0];
      }),
      take(1)
    );
  }

  getAgentNameLogo(agentName: string): Observable<string | undefined> {
    return this.agentsManifest$.pipe(
      map((agents) => agents.find((agent) => agent.name === agentName)?.logoUrl)
    );
  }

  getAgentMetadata(
    agentName: string,
    agentVersion: string
  ): Observable<AgentMetadataModel> {
    const cacheKey = this.getCacheKey(agentName, agentVersion);

    if (this.agentsCache.has(cacheKey)) {
      return this.agentsCache.get(cacheKey)!;
    }

    const agentMetadata$ = combineLatest({
      edition: this.edition$,
      supportedApps: this.flagsService.getArrayFlag(
        ApFlagId.SUPPORTED_APP_WEBHOOKS
      ),
    }).pipe(
      take(1),
      switchMap(({ edition, supportedApps }) => {
        return this.fetchAgentMetadata({
          agentName,
          agentVersion,
          edition,
        }).pipe(
          take(1),
          map((agentMetadata) => {
            return {
              ...agentMetadata,
              triggers: this.filterAppWebhooks(
                agentMetadata.name,
                agentMetadata.triggers,
                supportedApps
              ),
            };
          })
        );
      }),
      shareReplay(1)
    );

    this.agentsCache.set(cacheKey, agentMetadata$);
    return this.agentsCache.get(cacheKey)!;
  }

  getAgentActionConfigOptions<
    T extends DropdownState<unknown> | AgentPropertyMap
  >(req: AgentOptionRequest) {
    return this.http
      .post<T>(environment.apiUrl + `/agents/options`, req)
      .pipe(timeout(A_MIN_TIMEOUT_IN_MILLISECONDS * 5));
  }
  findNonAgentStepIcon(type: ActionType | TriggerType) {
    switch (type) {
      case ActionType.CODE:
        return {
          url: 'assets/img/newDesign/agents/code_mention.png',
          key: 'code',
        };
      case ActionType.BRANCH:
        return {
          url: 'assets/img/newDesign/agents/binary-choice.svg',
          key: 'branch',
        };
      case ActionType.ROUTER:
        return {
          url: 'assets/img/newDesign/agents/router.svg',
          key: 'router',
        };
      case ActionType.LOOP_ON_ITEMS:
        return {
          url: 'assets/img/newDesign/agents/loop.svg',
          key: 'loop',
        };
      case TriggerType.WEBHOOK:
        return {
          url: 'assets/img/newDesign/agents/webhook.svg',
          key: 'webhook',
        };
    }

    throw new Error("Step type isn't accounted for");
  }

  getAgentsManifestFromServer({
    includeHidden,
    searchQuery,
    suggestionType,
  }: ListAgentsRequestQuery) {
    return combineLatest([
      this.edition$,
      this.release$,
      this.clearCache$.asObservable().pipe(startWith(void 0)),
    ]).pipe(
      switchMap(([edition, release]) => {
        let params: Record<string, boolean | string> = {
          release,
          edition,
        };
        params = {
          ...params,
          ...spreadIfDefined('includeHidden', includeHidden),
          ...spreadIfDefined('searchQuery', searchQuery),
          ...spreadIfDefined('suggestionType', suggestionType),
        };
        return this.http.get<AgentMetadataModelSummary[]>(
          `${environment.apiUrl}/agents`,
          {
            params,
          }
        );
      }),
      shareReplay(1)
    );
  }
}

type AddAgentParams = Omit<AddAgentRequestBody, 'agentArchive'> & {
  agentArchive: File | null;
};
