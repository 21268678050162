import { Component, Input, OnChanges } from '@angular/core';
import { Highlights } from '../../models/Highlights-model';

@Component({
  selector: 'app-highlight-information',
  templateUrl: './highlight-information.component.html',
})
export class HighlightInformationComponent implements OnChanges {
  @Input() highlightsInput!: Highlights;
  @Input() title: string = 'case Information';
  leftColumnIdentifiers: any[] = [];
  rightColumnIdentifiers: any[] = [];

  ngOnChanges(): void {
    if (this.highlightsInput && this.highlightsInput.identifiers) {
      this.divideIdentifiers();
    }
  }
  private divideIdentifiers(): void {
    const identifiers = this.highlightsInput.identifiers;
    if (identifiers) {
      const middleIndex = Math.ceil(identifiers.length / 2);
      this.leftColumnIdentifiers = identifiers.slice(0, middleIndex);
      this.rightColumnIdentifiers = identifiers.slice(middleIndex);
    }
  }
}
