import { Static, Type } from '@sinclair/typebox'

export enum SortOrdering {
    DESC = 'DESC',
    ASC = 'ASC',
}

export const SearchEngineSimpleSortItem = Type.Object({
    fieldName: Type.String(),
    orderingOption: Type.Enum(SortOrdering),
})

export type SearchEngineSimpleSortItem = Static<typeof SearchEngineSimpleSortItem>

export const SearchEngineSimpleSortOptions = Type.Array(SearchEngineSimpleSortItem)

export type SearchEngineSimpleSortOptions = Static<typeof SearchEngineSimpleSortOptions>
