<div *ngIf="!framerCookiesDismissed" class="fixed-bottom-center">
  <div
    class="ap-font-sans ap-max-h-[calc(100vh_-_40px)] ap-flex ap-flex-col ap-gap-3 ap-relative ap-z-[100] ap-pointer-events-auto ap-opacity-100 ap-transform-none"
  >
    <div
      class="ap-bg-gray-200 ap-shadow-inner ap-rounded-xl ap-w-full ap-max-w-[600px]"
    >
      <div class="ap-p-5">
        <div *ngIf="!customizeBtn; else customizeTemplate">
          <p
            class="ap-text-lg ap-font-semibold ap-mb-2 ap-leading-none ap-text-gray-800"
          >
            Cookie Settings
          </p>
          <p class="ap-text-sm ap-text-gray-800 ap-leading-relaxed">
            We use cookies to enhance your experience and analyze site traffic.
            <span>
              Read our
              <a
                href="/cookie-policy"
                target="_blank"
                class="ap-text-blue-600 ap-no-underline ap-hover:underline-none"
              >
                Cookie Policy
              </a>
            </span>
          </p>
        </div>
        <ng-template #customizeTemplate>
          <ap-cookie-customize
            [cookies]="cookies"
            (cookiesChanged)="onCookiesChanged($event)"
          ></ap-cookie-customize>
        </ng-template>

        <div class="ap-flex ap-flex-row ap-gap-2 ap-mt-4">
          <button
            (click)="rejectAll()"
            class="ap-w-full ap-py-2 ap-px-4 ap-rounded-md ap-bg-gray-300 ap-text-gray-800 ap-text-base ap-cursor-pointer ap-hover:no-underline ap-text-center"
          >
            Reject all
          </button>

          <button
            (click)="customizeBtn = true"
            class="ap-w-full ap-py-2 ap-px-4 ap-rounded-md ap-bg-gray-300 ap-text-gray-800 ap-text-base ap-cursor-pointer ap-hover:no-underline ap-text-center"
          >
            Customize
          </button>

          <button
            (click)="acceptAll()"
            class="ap-w-full ap-py-2 ap-px-4 ap-rounded-md ap-bg-teal-600 ap-text-white ap-text-base ap-cursor-pointer ap-hover:no-underline ap-text-center"
          >
            Accept all
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
