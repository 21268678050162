import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
  lottieOptions = { path: '/assets/lottie/empty-state.json' };

  @Input({ required: true }) title: string;
  @Input() description?: string;
  @Input() actionButton?: {
    btnText: string;
    btnOnClick: () => void;
    loading?: boolean;
  };

  ngOnChanges() {
    // Handle changes to actionButton if needed
    // This can be used to react to changes in loading state
  }
}
