import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookiesDismissed } from '../../../models/cookies-dismissed';
@Component({
  selector: 'ap-cookie-customize',
  templateUrl: './cookie-customize.component.html',
  styleUrls: ['./cookie-customize.component.scss'],
})
export class CookieCustomizeComponent {
  @Output() cookiesChanged = new EventEmitter<CookiesDismissed>();
  @Input() set cookies(cookies: CookiesDismissed) {
    if (cookies) {
      this.dataCookies = cookies;
    }
  }
  dataCookies = new CookiesDismissed();
  toggleCookie(cookieKey: keyof CookiesDismissed): void {
    if (cookieKey === 'necessary') {
      return;
    }
    this.dataCookies[cookieKey] = !this.dataCookies[cookieKey];
  }

  savePreferences(): void {
    this.cookiesChanged.emit(this.dataCookies);
  }
}
