<div
  class="ap-flex ap-flex-col ap-w-80 ap-h-[300px] ap-bg-[#FCFCFD] ap-border ap-border-[#D6DBF5] ap-rounded-[20px] ap-p-6"
>
  <div
    class="ap-w-full ap-h-10 ap-flex ap-items-center ap-overflow-hidden ap-gap-1"
  >
    <ng-container
      *ngFor="let agentData of template.agentsIcons | slice : 0 : 6"
    >
      <ng-container *ngIf="agentData.iconUrl">
        <app-agent-icon [iconSrc]="agentData.iconUrl"></app-agent-icon>
      </ng-container>
    </ng-container>
  </div>
  <div
    class="ap-text-xl ap-font-semibold ap-font-inter ap-text-[#475467] ap-mt-6 ap-line-clamp-2"
    [matTooltip]="template.name"
    [matTooltipDisabled]="!isTextTruncated(titleElement)"
    matTooltipClass="template-tooltip"
    #titleElement
  >
    {{ template.name }}
  </div>
  <div
    class="ap-text-sm ap-font-normal ap-font-inter ap-text-[#667085] ap-mt-2 ap-line-clamp-4"
    [matTooltip]="template.description"
    [matTooltipDisabled]="!isTextTruncated(descriptionElement)"
    matTooltipClass="template-tooltip"
    #descriptionElement
  >
    {{ template.description }}
  </div>
  <a
    class="ap-cursor-pointer ap-mt-auto ap-no-underline ap-text-lg ap-text-[#175cd3] ap-font-semibold ap-font-inter"
    (click)="handleConfigure()"
  >
    <div
      class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
    >
      Configure
      <svg-icon
        [svgStyle]="{ width: '20px', height: '20px' }"
        class="ap-flex ap-justify-center ap-items-center"
        src="assets/img/newDesign/onboarding/chevron-right.svg"
      >
      </svg-icon>
    </div>
  </a>
</div>
