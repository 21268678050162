import { Component, EventEmitter, Input, Output } from '@angular/core';
interface HasName {
  name: string;
  title: string;
  description: string;
  icon: string;
}
@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
})
export class SearchItemComponent<T extends HasName> {
  @Input() set list(listData: T[]) {
    this._list = listData;
    this.filterItems();
  }
  @Output() itemCallback = new EventEmitter<T>();
  searchText!: string;
  private _list!: T[];
  filteredItems!: T[];

  itemClick(item: T): void {
    this.itemCallback.emit(item);
  }

  filterItems(): void {
    if (this._list && this.searchText) {
      this.filteredItems = this._list.filter((item) =>
        item.title.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  selectItem(item: T): void {
    this.itemCallback.emit(item);
  }
}
