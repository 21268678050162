export type Case = {
    caseId: number
    flowDisplayName: string
    updated: Date
    status: string
    type: string
}
export type CasesListResponse<T> = {
    totalItems: number
    data: T[]
    next: string | null
    previous: string | null
}

export type CaseFunctions = {
    caseFunctionId: string
    caseFunctionName: string
}
export type CasesList = {
    caseId: string
    lastUpdateTimestamp: number
    inboxIds: string[]
    teamId: number
    creationTimestamp: number
    caseName: string
    projectId: string
    caseSummary: string
    caseType: string
    caseFunctions: CaseFunctions[]
}

export type Highlights = {
    _id: string
    identifiers: Identifier[]
    caseSummary: string | undefined
}
export type Identifier = {
    identifierName: string
    identifierId: string
}

export type HighlightsRouter = {
    caseId: string
    projectId: string
}
export type FilteringItem = {
    fieldName: string
    fieldValue: string
    comparisonType:
        | 'EQUAL'
        | 'NOT_EQUAL'
        | 'GREATER_THAN'
        | 'LESS_THAN'
        | 'LIKE'
}

export type Filters = {
    filterOperation: 'AND' | 'OR'
    filteringItems: FilteringItem[]
}

export type SortCriteria = {
    fieldName: string
    ordering: 'ASC' | 'DESC'
}

export type TableRequestModel = {
    searchValue?: string | null
    functionId?: string | null
    functionName?: string | null
    pageNumber: number
    pageSize: number
    filters?: Filters
    sort?: SortCriteria[]
}
