<div class="ap-p-7">
  <!-- Title Skeleton -->
  <div class="ap-bg-gray-300 ap-h-6 ap-w-1/2 ap-rounded ap-animate-pulse"></div>

  <!-- Summary Skeleton -->
  <div
    class="ap-bg-gray-300 ap-h-4 ap-w-full ap-rounded ap-mt-4 ap-mb-6 ap-animate-pulse"
  ></div>

  <!-- Columns Skeleton -->
  <div
    class="ap-flex ap-flex-wrap ap-items-start ap-gap-4 md:ap-gap-6 lg:ap-gap-8"
  >
    <!-- Left Column Skeleton -->
    <div class="ap-flex ap-flex-col ap-gap-4 ap-min-w-[40%] ap-max-w-[50%]">
      <div class="ap-flex ap-items-center ap-gap-4 md:ap-gap-6">
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/4 ap-rounded ap-animate-pulse"
        ></div>
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/3 ap-rounded ap-animate-pulse"
        ></div>
      </div>
      <div class="ap-flex ap-items-center ap-gap-4 md:ap-gap-6">
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/4 ap-rounded ap-animate-pulse"
        ></div>
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/3 ap-rounded ap-animate-pulse"
        ></div>
      </div>
    </div>

    <!-- Right Column Skeleton -->
    <div class="ap-flex ap-flex-col ap-gap-4 ap-min-w-[40%] ap-max-w-[50%]">
      <div class="ap-flex ap-items-center ap-gap-4 md:ap-gap-6">
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/4 ap-rounded ap-animate-pulse"
        ></div>
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/3 ap-rounded ap-animate-pulse"
        ></div>
      </div>
      <div class="ap-flex ap-items-center ap-gap-4 md:ap-gap-6">
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/4 ap-rounded ap-animate-pulse"
        ></div>
        <div
          class="ap-bg-gray-300 ap-h-4 ap-w-1/3 ap-rounded ap-animate-pulse"
        ></div>
      </div>
    </div>
  </div>
</div>
