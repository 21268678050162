// error-alert.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-error-alert',
  templateUrl: './error-alert.component.html',
})
export class ErrorAlertComponent {
  @Input({ required: true }) message!: string; // Required message input
  @Input() showBorder: boolean = true;
  @Input() actionButton: { label: string; loading: boolean } = {
    label: 'Retry',
    loading: false,
  };
  @Input() showButton: boolean = true;
  @Output() action = new EventEmitter<void>();
  

  onRetry() {
    this.actionButton.loading = true;
    this.action.emit();
  }
}
