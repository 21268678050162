export const environment = {
  igniteUrl: 'https://portal-de.ai-copilot.dev/',
  extractUrl: "https://xtract-de.ai-copilot.dev/",
  hashSecretKey:"secret",
  production: false,
  apiUrl: 'https://frontend.ai-copilot.dev/api/v1',
  jwtTokenName: 'token',
  redirectUrl: 'https://frontend.ai-copilot.dev/redirect',
  userPropertyNameInLocalStorage: 'currentUser',
};
