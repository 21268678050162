<div
  class="ap-h-10 ap-bg-[#1570EF] ap-flex ap-justify-between ap-items-center ap-z-50 ap-relative"
>
  <div
    class="ap-ml-6 ap-flex ap-flex-row ap-gap-2 ap-justify-center ap-items-center"
  >
    <svg-icon
      [applyClass]="true"
      [svgStyle]="{ height: '20px' }"
      src="assets/img/newDesign/upbrains/upbrains-ai-logo.svg"
    ></svg-icon>
  </div>

  <div class="ap-mr-[10px] ap-flex ap-flex-row ap-items-center">
    <ng-container *ngIf="!isOnboarding">
      <div class="ap-w-[1px] ap-h-[14px] ap-bg-[#FFFFFF33] ap-mr-4"></div>
      <ng-container *ngIf="planName">
        <ap-subscription-banner [showCurrentPlan]="true" />
      </ng-container>
      <ap-subscription-banner />

      <div class="ap-w-[1px] ap-h-[14px] ap-bg-[#FFFFFF33] ap-mr-8"></div>
    </ng-container>

    <div class="ap-cursor-pointer" (click)="toggleProfile()">
      <svg-icon
        [applyClass]="true"
        [svgStyle]="{ width: '27.95px', height: '27.95px' }"
        src="assets/img/newDesign/header/vector.svg"
      ></svg-icon>
    </div>
    <div
      class="ap-absolute ap-top-10 ap-right-3 ap-border ap-border-[#F2F4F7] ap-rounded-lg ap-w-[287px] ap-flex ap-flex-col custom-profile ap-z-50 ap-bg-white"
      [ngClass]="{
      'ap-block': showProfile,
      'ap-hidden': !showProfile,
    }"
      (click)="onProfileClick($event)"
    >
      <div class="ap-flex ap-flex-row ap-items-center ap-gap-3 ap-px-4 ap-py-3">
        <svg-icon
          [applyClass]="true"
          [svgStyle]="{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
        }"
          src="assets/img/newDesign/side-menu/admin.svg"
        ></svg-icon>
        <div class="ap-flex ap-flex-col ap-max-w-[200px]">
          <div class="ap-text-sm ap-font-inter ap-font-semibold">
            {{ profile.name }}
          </div>
          <div
            class="ap-text-sm ap-font-inter ap-font-normal ap-no-underline ap-text-[#475467] ap-truncate"
            [matTooltip]="profile.email"
          >
            {{ profile.email }}
          </div>
        </div>
      </div>
      <div
        class="ap-px-4 ap-py-[10px] ap-text-sm ap-font-inter ap-font-medium ap-border-t ap-border-[#F2F4F7] ap-cursor-pointer"
        (click)="logout()"
      >
        Log out
      </div>
    </div>
  </div>
</div>
