import { createAction, props } from '@ngrx/store';
import { SubscriptionPlansType } from '@upbrains/shared';

export const setSubscriptionPlan = createAction(
  '[Subscription Plan] Set Subscription Plan',
  props<{
    subscriptionPlan: Partial<SubscriptionPlansType> | undefined | null;
  }>()
);

export const setSubscriptionPlanInfo = createAction(
  '[Subscription Plan] Set Subscription Plan',
  props<{
    subscriptionPlan: Partial<SubscriptionPlansType> | undefined | null;
  }>()
);
