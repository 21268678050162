import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const DEFAULT_FORBIDDEN_CHARACTERS = [
  '<',
  '>',
  '{',
  '}',
  '(',
  ')',
  '[',
  ']',
  '/',
  '\\',
  ':',
  ';',
  '"',
  "'",
  '&',
];
/**
 * forbiddenCharactersValidator
 * Ensure no code, URL, html/json data is entered into any fields in sign up and sign in forms
 */

export function forbiddenCharactersValidator(
  forbiddenChars: string[] = DEFAULT_FORBIDDEN_CHARACTERS
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const value = String(control.value);

    const charsToCheck =
      forbiddenChars.length > 0 ? forbiddenChars : DEFAULT_FORBIDDEN_CHARACTERS;
    const invalidChars = charsToCheck.filter((char) => value.includes(char));

    return invalidChars.length > 0
      ? { forbiddenCharacters: { invalidChars } }
      : null;
  };
}
