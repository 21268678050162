import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FlowTemplateWithIcons } from '@upbrains/shared';

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss'],
})
export class AgentCardComponent {
  @Input() template: FlowTemplateWithIcons;
  @Input() action: (template: FlowTemplateWithIcons) => void;

  @ViewChild('titleElement') titleElement!: ElementRef;
  @ViewChild('descriptionElement') descriptionElement!: ElementRef;

  handleConfigure() {
    if (this.action) {
      this.action(this.template);
    }
  }
  ngAfterViewInit() {
    this.checkTruncation();
  }

  isTextTruncated(element: HTMLElement): boolean {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  private checkTruncation(): void {
    // Recheck truncation after view is initialized and content is rendered
    setTimeout(() => {
      this.isTextTruncated(this.titleElement.nativeElement);
      this.isTextTruncated(this.descriptionElement.nativeElement);
    });
  }
}
