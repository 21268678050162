<ng-container *ngIf="subscription$ | async as subscription">
  <ap-button
    actionButton
    [btnSize]="!showCurrentPlan ? 'medium' : 'small'"
    [btnColor]="!showCurrentPlan ? 'white' : 'basic'"
    btnStyle="basic"
    class="ap-flex ap-flex-row ap-mr-4 custom-button"
    (buttonClicked)="handleUpgrade()"
    i18n
    [ngClass]="{'ap-text-white': showCurrentPlan}"
  >
    <svg-icon
      *ngIf="!showCurrentPlan"
      [svgStyle]="{ width: '11.14px', height: '12.38px' }"
      src="assets/img/newDesign/header/upgrade.svg"
    >
    </svg-icon>
    <div>
      {{!showCurrentPlan ? 'Upgrade Now' : planName }} {{shouldShowTrial()}}
    </div>
  </ap-button>
</ng-container>
