<ap-dialog-title-template i18n>Create Template </ap-dialog-title-template>
<mat-dialog-content>
  <form
    class="ap-flex ap-flex-col ap-gap-1 ap-w-[31.25rem]"
    [formGroup]="form"
    (submit)="submit()"
  >
    <mat-form-field>
      <input matInput placeholder="Name" formControlName="name" required />
      <mat-error *ngIf="form.controls.name.invalid">Name is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea
        matInput
        placeholder="Description"
        formControlName="description"
        required
      ></textarea>
      <mat-error *ngIf="form.controls.description.invalid"
        >Description is required</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Select Type</mat-label>
      <mat-select [(value)]="selected">
        <mat-option value="PLATFORM">PLATFORM</mat-option>
        <mat-option value="PROJECT">PROJECT</mat-option>
        <mat-option value="GLOBAL">GLOBAL</mat-option>
      </mat-select>
    </mat-form-field>
    <span class="ap-text-red-500" *ngIf="error">{{error}}</span>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div mat-dialog-actions>
    <button mat-button (click)="closeDialog()">Cancel</button>
    <button
      mat-button
      color="primary"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      Create
    </button>
  </div>
</mat-dialog-actions>
