<div
  class="ap-flex ap-flex-col ap-w-80 ap-h-[300px] ap-bg-[#FCFCFD] ap-border ap-border-[#D6DBF5] ap-rounded-[20px] ap-p-6"
>
  <div
    class="ap-w-full ap-h-14 ap-flex ap-flex-row ap-items-center ap-overflow-hidden"
  >
    <ngx-skeleton-loader
      class="ap-h-10 ap-w-10"
      [count]="6"
      [appearance]="'circle'"
    ></ngx-skeleton-loader>
  </div>
  <ngx-skeleton-loader
    class="ap-text-xl ap-font-semibold ap-font-inter ap-text-[#475467] ap-mt-6"
    [count]="1"
    [appearance]="'line'"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    class="ap-text-sm ap-font-normal ap-font-inter ap-text-[#667085] ap-mt-[10px]"
    [count]="1"
    [appearance]="'line'"
  ></ngx-skeleton-loader>
  <div
    class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter ap-mt-auto"
  >
    <ngx-skeleton-loader
      class="ap-text-lg ap-font-semibold"
      [count]="1"
      [appearance]="'line'"
    ></ngx-skeleton-loader>
  </div>
</div>
