import { Static, Type } from '@sinclair/typebox'


export const CaseTemplateCreationParamDto = Type.Object({
    projectId: Type.String(),
})

export type CaseTemplateCreationParamDto = Static<typeof CaseTemplateCreationParamDto>

export const CaseTemplateCreationRequestBodyDto = Type.Object({
    caseType: Type.String(),
    caseFunctionName: Type.String(),
    caseIdentifierKeys: Type.Array(Type.String()),
})

export const CaseTemplatePartialUpdateRequestBodyDto = Type.Partial(CaseTemplateCreationRequestBodyDto)

export const CaseTemplatePartialUpdateParamDto = Type.Object({
    projectId: Type.String(),
    caseTemplateId: Type.String(),
})


export type CaseTemplatePartialUpdateParamDto = Static<typeof CaseTemplatePartialUpdateParamDto>
export type CaseTemplatePartialUpdateRequestBodyDto = Static<typeof CaseTemplatePartialUpdateRequestBodyDto>

export const CaseTemplateGetOneByIdParamDto = Type.Object({
    projectId: Type.String(),
    caseTemplateId: Type.String(),
})
export type CaseTemplateGetOneByIdParamDto = Static<typeof CaseTemplateGetOneByIdParamDto>


export const CaseTemplateGetAllParamDto = Type.Object({
    projectId: Type.String(),
})

export type CaseTemplateGetAllParamDto = Static<typeof CaseTemplateGetAllParamDto>

