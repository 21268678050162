import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import {
  ConversationDetails,
  ConversationResponse,
  PayloadMessage,
} from '@upbrains/shared';
@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {}

  list(params: {
    searchValue?: string;
    functionId?: string;
    caseId?: string;
    pageNumber?: number;
    pageSize?: number;
  }): Observable<ConversationResponse> {
    const projectId = this.authenticationService.getProjectId();
    const body = {
      pageNumber: 1,
      pageSize: 20,
      ...(params.searchValue && { searchValue: params.searchValue }),
      ...(params.pageNumber && { pageNumber: params.pageNumber }),
      ...(params.pageSize && { pageSize: params.pageSize }),
      ...(params.functionId &&
        params.caseId && {
          filters: {
            filterOperation: 'AND',
            filteringItems: [
              {
                fieldName: 'functionId',
                fieldValue: params.functionId || '',
                comparisonType: 'EQUAL',
              },
              {
                fieldName: 'caseIds',
                fieldValue: params.caseId || '',
                comparisonType: 'EQUAL',
              },
            ],
          },
        }),
    };

    // Construct the new API endpoint
    const apiUrl = `${environment.apiUrl}/projects/${projectId}/conversations/`;

    // Send a POST request with an empty object as the payload
    return this.http.post<ConversationResponse>(apiUrl, body);
  }

  getAll() {
    return this.http.get<any>(environment.apiUrl + '/conversation/list');
  }

  // Fetch details of a specific conversation including highlights
  getConversationDetails(
    conversationId: string
  ): Observable<ConversationDetails> {
    const projectId = this.authenticationService.getProjectId();
    const apiUrl = `${environment.apiUrl}/projects/${projectId}/conversations/${conversationId}`;
    return this.http.get<ConversationDetails>(apiUrl);
  }

  // Fetch messages for a specific conversation

  getMessages(conversationId: string): Observable<ConversationResponse> {
    const projectId = this.authenticationService.getProjectId();
    const apiUrl = `${environment.apiUrl}/projects/${projectId}/conversations/${conversationId}/messages`;
    return this.http.post<ConversationResponse>(apiUrl, {
      pageNumber: 1,
      pageSize: 5,
    });
  }

  // Fetch payload for a specific message
  getMessagePayload(messageId: string): Observable<PayloadMessage> {
    const projectId = this.authenticationService.getProjectId();
    const apiUrl = `${environment.apiUrl}/projects/${projectId}/messages/${messageId}/payloads`;
    return this.http.get<PayloadMessage>(apiUrl);
  }
}
