import { Static, Type } from '@sinclair/typebox'

export const PlansInfoObject = Type.Object({
    billing_period: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    excess_unit_price: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    fixed_amount: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    id: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    name: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    service_name: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    stripe_excess_price_id: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
    stripe_price_id: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    subscription_credit_limit: Type.Optional(
        Type.Union([Type.Number(), Type.Null()]),
    ),
    transaction_limit: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    users_limit: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
})

export const PlansInfo = Type.Object({
    plans_info: Type.Array(PlansInfoObject),
})

export const PlansInfoApi = Type.Object({
    results: PlansInfo,
    status: Type.Number(),
})

export const AllUserSubscriptionsType = Type.Object({
    additional_credits: Type.Union([Type.String(), Type.Null()]),
    channel_type: Type.String(),
    exceeded_limit: Type.Boolean(),
    id: Type.Number(),
    plan_id: Type.Number(),
    quantity: Type.Number(),
    recurring_charge: Type.Union([Type.Number(), Type.Null()]),
    renewal_date: Type.String(),
    service_id: Type.Number(),
    status: Type.String(),
    stripe_excess_subscription_id: Type.Union([Type.String(), Type.Null()]),
    stripe_subscription_id: Type.String(),
    team_id: Type.Number(),
})

export const GetAllSubscriptionsType = Type.Object({
    all_user_subscriptions: Type.Array(AllUserSubscriptionsType),
    status: Type.Number(),
})

export type PlansInfoObjectType = Static<typeof PlansInfoObject>
export type PlansInfoType = Static<typeof PlansInfo>
export type PlansInfoApiType = Static<typeof PlansInfoApi>
export type AllUserSubscriptionsType = Static<typeof AllUserSubscriptionsType>
export type GetAllSubscriptionsType = Static<typeof GetAllSubscriptionsType>
