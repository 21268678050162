import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FlowOperationType, FlowTemplateWithIcons } from '@upbrains/shared';
import {
  AgentMetadataModelSummary,
  AgentService,
  AppConnectionsService,
  AuthenticationService,
  FlowService,
  onboardingSelectors,
} from '@upbrains/ui/common';
import { AgentMetadataService } from '@upbrains/ui/feature-agents';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { finalize, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-agent-connection',
  templateUrl: './agent-connection.component.html',
  styleUrls: ['./agent-connection.component.scss'],
})
export class AgentConnectionComponent implements OnInit {
  newConnectionAgent?: AgentMetadataModelSummary;

  @Input() onNextStep!: () => void;
  agentTemplate$: Observable<FlowTemplateWithIcons | null> = of(null);
  agents$: Observable<AgentMetadataModelSummary[]>;
  agentsWithAuth$: Observable<
    (AgentMetadataModelSummary & { connected: boolean })[]
  > = of([]);
  allAgentsConnected = false;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading = false;
  agentId: string | null = null;
  constructor(
    private store: Store,
    private agentMetadataService: AgentMetadataService,
    private connectionsService: AppConnectionsService,
    private authenticationService: AuthenticationService,
    private connectionService: AppConnectionsService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private router: Router,
    private flowService: FlowService,
    private agentService: AgentService
  ) {
    this.agents$ = this.agentMetadataService
      .getAgentsManifestFromServer({})
      .pipe(map((agents) => agents.filter((p) => !!p.auth)));
  }

  ngOnInit() {
    this.agentTemplate$ = this.store.select(
      onboardingSelectors.selectSelectedAgentTemplate
    );
    this.agentTemplate$.subscribe();

    this.loading = true;
    this.agentsWithAuth$ = this.agentTemplate$.pipe(
      tap(() => {
        this.loading = true;
      }),
      switchMap((template) => {
        if (template && template.agents) {
          return this.agents$.pipe(
            switchMap((agents) => {
              return this.connectionsService
                .list({
                  projectId: this.authenticationService.getProjectId(),
                  limit: 50,
                })
                .pipe(
                  map((connections) => {
                    const connectedAgentNames = connections.data.map(
                      (connection) => connection.agentName
                    );
                    return agents
                      .filter((agent) =>
                        template.agents.some(
                          (agentName) => agent.name === agentName
                        )
                      )
                      .map((agent) => ({
                        ...agent,
                        connected: connectedAgentNames.includes(agent.name),
                      }));
                  })
                );
            })
          );
        }
        return of([]);
      }),
      finalize(() => {
        this.loading = false;
      })
    );
    this.agentsWithAuth$.subscribe();
    this.loading = false;
  }

  getConnectionAndUpdateAgents() {
    this.connectionService
      .list({ projectId: this.authenticationService.getProjectId(), limit: 50 })
      .pipe(
        switchMap((connections) => {
          return this.agentsWithAuth$.pipe(
            map((agents) => {
              return agents.map((agent) => ({
                ...agent,
                connected: connections.data.some(
                  (connection) => connection.agentName === agent.name
                ),
              }));
            })
          );
        }),
        tap((updatedAgents) => {
          this.agentsWithAuth$ = of(updatedAgents);
          this.allAgentsConnected = updatedAgents.every(
            (agent) => agent.connected
          );
          this.changeDetectorRef.detectChanges();
          if (this.allAgentsConnected) {
            this.snackBar
              .open(
                'All agent connections have been successfully established',
                '',
                {
                  panelClass: 'success',
                }
              )
              .afterDismissed()
              .subscribe(() => {
                this.createNewFlowAndAgent();
              });
          }
        })
      )
      .subscribe();
  }

  private createNewFlowAndAgent() {
    const createFlowObservable = this.flowService
      .create({
        projectId: this.authenticationService.getProjectId(),
        displayName: $localize`Untitled`,
        folderId: undefined,
      })
      .pipe(
        switchMap((flow) => {
          this.loading$.next(true);
          return this.agentService
            .create({
              projectId: this.authenticationService.getProjectId(),
              displayName: flow.version.displayName,
              flowId: flow.id,
              folderId: undefined,
            })
            .pipe(
              switchMap((agent) => {
                this.agentId = agent.id;

                // Update the flow using the agentTemplate$ observable
                return this.agentTemplate$.pipe(
                  switchMap((template) => {
                    return this.flowService
                      .update(flow.id, {
                        type: FlowOperationType.IMPORT_FLOW,
                        request: {
                          displayName: flow.version.displayName,
                          trigger:
                            template?.template.trigger ?? flow.version.trigger,
                        },
                      })
                      .pipe(
                        map(() => ({
                          flow,
                          agent,
                        }))
                      );
                  })
                );
              })
            );
        }),
        tap({
          next: ({ agent, flow }) => {
            this.loading$.next(false);
            this.router.navigate(['/agents', agent.id, 'flows', flow.id]);
          },
          error: () => {
            this.loading$.next(false);
          },
        })
      );

    // Subscribe to the observable
    createFlowObservable.subscribe();
  }
}
