export class TableColumns<T> {
  name?: keyof T;
  type:
    | 'text'
    | 'number'
    | 'date'
    | 'longText'
    | 'btn'
    | 'children'
    | 'select'
    | 'component' = 'text';
  title?: string;
  isSort?: boolean;
  isEdit?: boolean;
  component?: any;
  componentParams?: any;
  onClick?: (item: T) => void;
  onEdit?: (index: number, columnKey: string, value: string) => void;
  children?: TableColumns<T>[];
  width?: number;
  icon?: any;
  render?: (item: T) => any;
}

export class TableData<T> {}

export class TableConfig<T> {
  Pk!: keyof T;
  onRowClick?: (item: T) => void;
  onEditCell?: (item: T) => void;
  onClickCell?: (item: T) => void;
  height?: string;
  constructor(height?: string) {
    this.height = height;
  }
}

export class TableHelper {
  static convertToTableColumns<T>(
    data: T | any,
    component: any,
    sortList: any,
    componentParams: any,
    onEdit?: (index: number, columnKey: string, value: string) => void,
    onClick?: (item: T) => void
  ) {
    const convertType = (type: string) => {
      switch (type) {
        case 'String':
          return 'text';
        case 'Number':
          return 'number';
        case 'Address':
          return 'longText';
        default:
          return 'text';
      }
    };

    if (!data.length) return;

    // Filter the list to include only keys present in the valueObject
    const filteredList = Object.keys(data[0]).filter((item) =>
      Object.prototype.hasOwnProperty.call(sortList || {}, item)
    );

    // Sort the filtered list based on the values in the valueObject
    const sortedList = filteredList.sort((a, b) => {
      return sortList[a] - sortList[b];
    });

    const columns = sortedList.map((key) => {
      if (
        data[0][key] &&
        data[0][key]?.matches &&
        data[0][key]?.matches[0]?.candidates
      ) {
        const ls: any[] = data[0][key]?.matches[0]?.candidates?.map(
          (dr: any) => dr?.matched_value || dr?.[key]
        );
        if (ls.length > 0) {
          return {
            type: 'component',
            name: data[0][key]?.field_name,
            title: data[0][key]?.field_name,
            isEdit: true,
            onEdit: onEdit,
            component: component,
            componentParams: componentParams,
            onClick: onClick,
            width: 500,
          } as TableColumns<T>;
        }
        return {
          type: 'text',
          width: 150,
          name: data[0][key]?.field_name,
          title: data[0][key]?.field_name,
          isEdit: true,
          onEdit: onEdit,
          onClick: onClick,
        } as TableColumns<T>;
      } else {
        return {
          width: 100,
          name: data[0][key]?.field_name,
          title: data[0][key]?.field_name,
          type: convertType(data[0][key].type),
          isEdit: true,
          onEdit: onEdit,
          onClick: onClick,
        } as TableColumns<T>;
      }
    });

    return columns.filter((dr) => dr.name != undefined);
  }
  static convertToTableData<T>(data: T | any) {
    let rowData: any[] = [];
    data?.forEach((item: any) => {
      const k = Object.keys(item).reduce((acc, key) => {
        if (item[key]?.matches?.[0]?.candidates) {
          const options = item[key]?.matches[0]?.candidates.map(
            (dr: any) => dr?.matched_value || dr?.[key]
          );

          const result = item[key]?.matches[0]?.result[0]?.matched_value
            ? item[key].matches[0].result[0].matched_value
            : item[key].matches[0].result[0]?.[key];
          if (result && !options.includes(result)) {
            options.unshift(result);
          }

          return {
            ...acc,
            [item[key].field_name]: {
              value: item[key].content,
              options: [...new Set(options)],
              select: result,
            },
          };
        }

        return {
          ...acc,
          [item[key].field_name]: item[key].content,
        };
      }, {});
      rowData.push(k);
    });

    return rowData;
  }

  static trashIcon(params: any) {
    let onClick: (index: number, data: any) => void = () => {};

    // Create button element
    const element = document.createElement('button');
    element.setAttribute('type', 'button');
    element.setAttribute('aria-label', 'Delete Row');
    element.classList.add(
      'mat-mdc-tooltip-trigger',
      'mdc-icon-button',
      'mat-mdc-icon-button',
      'mat-warn',
      'mat-mdc-button-base',
      'mat-mdc-button-persistent-ripple'
    );

    // Create SVG icon wrapper
    const svgIconWrapper = document.createElement('span');
    svgIconWrapper.classList.add(
      'svgClasses',
      'ap-flex',
      'ap-items-center',
      'ap-justify-center'
    );

    // Create SVG element
    const svgElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    svgElement.setAttribute('width', '20');
    svgElement.setAttribute('height', '20');
    svgElement.setAttribute('viewBox', '0 0 20 20');
    svgElement.setAttribute('fill', 'none');
    svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    // Add SVG path elements
    const paths = [
      {
        d: 'M17.5 4.98356C14.725 4.70856 11.9333 4.56689 9.15 4.56689C7.5 4.56689 5.85 4.65023 4.2 4.81689L2.5 4.98356',
        stroke: '#475467',
      },
      {
        d: 'M7.08398 4.1415L7.26732 3.04984C7.40065 2.25817 7.50065 1.6665 8.90898 1.6665H11.0923C12.5007 1.6665 12.609 2.2915 12.734 3.05817L12.9173 4.1415',
        stroke: '#475467',
      },
      {
        d: 'M15.7077 7.6167L15.166 16.0084C15.0743 17.3167 14.9993 18.3334 12.6743 18.3334H7.32435C4.99935 18.3334 4.92435 17.3167 4.83268 16.0084L4.29102 7.6167',
        stroke: '#475467',
      },
      {
        d: 'M8.60742 13.75H11.3824',
        stroke: '#475467',
      },
      {
        d: 'M7.91602 10.4165H12.0827',
        stroke: '#475467',
      },
    ];

    paths.forEach(({ d, stroke }) => {
      const path = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      path.setAttribute('d', d);
      path.setAttribute('stroke', stroke);
      path.setAttribute('stroke-width', '1.5');
      path.setAttribute('stroke-linecap', 'round');
      path.setAttribute('stroke-linejoin', 'round');
      svgElement.appendChild(path);
    });

    // Append SVG to wrapper
    svgIconWrapper.appendChild(svgElement);
    element.appendChild(svgIconWrapper);

    // Add event listener
    element.addEventListener('click', () =>
      onClick(params.node.rowIndex, params.data)
    );

    return {
      element,
      setOnClick: (callback: (params: any) => void) => (onClick = callback),
    };
  }
}
