import { Static, Type } from '@sinclair/typebox'
import { SearchEngineSimpleFilters, SearchEngineSimpleSortOptions } from '.'

export const PaginationOptions = Type.Object({
    pageNumber: Type.Number(),
    pageSize: Type.Number(),
})

export const SimpleSearchOptions = Type.Object({
    searchValue: Type.Optional(Type.String()),
    filters: Type.Optional(SearchEngineSimpleFilters),
})

export const SimplePaginatedSearchOptions = Type.Intersect([
    PaginationOptions,
    SimpleSearchOptions,
])


export const SimplePaginatedSearchWithSortOptions =  Type.Intersect([
    SimplePaginatedSearchOptions,
    Type.Object({
        sort: Type.Optional(SearchEngineSimpleSortOptions),
    }),
])

export type PaginationOptions = Static<typeof PaginationOptions>

export type SimpleSearchOptions = Static<typeof SimpleSearchOptions>

export type SimplePaginatedSearchOptions = Static<typeof SimplePaginatedSearchOptions>

export type SimplePaginatedSearchWithSortOptions = Static<typeof SimplePaginatedSearchWithSortOptions>