<div
  class="ap-w-full ap-h-full ap-flex ap-flex-col ap-gap-6 ap-items-center ap-justify-start"
>
  <div>
    <ng-lottie
      [options]="lottieOptions"
      width="150px"
      height="139px"
      class="ap-text-center ap-h-[139px] ap-w-[150px]"
    ></ng-lottie>
  </div>

  <div class="ap-flex ap-flex-col ap-items-center ap-gap-3 ap-text-center">
    <h3 class="ap-text-3xl ap-font-semibold ap-text-center ap-text[#101828]">
      {{ title }}
    </h3>
    <p *ngIf="description" class="ap-text-base ap-text-center ap-text-gray-600">
      {{ description }}
    </p>
  </div>
  <div *ngIf="actionButton">
    <ap-button
      btnSize="medium"
      btnColor="primary"
      (click)="actionButton.btnOnClick()"
      [loading]="actionButton.loading ?? false"
      [disabled]="actionButton.loading ?? false"
    >
      {{ actionButton.btnText }}
    </ap-button>
  </div>
</div>
