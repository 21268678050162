<div class="ap-w-full ap-flex ap-flex-col ap-mb-15">
  <div
    class="ap-flex ap-flex-col ap-justify-between ap-items-center ap-font-inter ap-text-[#344054]"
  >
    <div
      class="ap-flex ap-flex-col ap-justify-center ap-items-center ap-h-[190px]"
    >
      <div class="ap-font-bold ap-text-5xl">Pick your Plan</div>
      <div class="ap-font-normal ap-text-xl ap-mt-11">
        Start automating your inbox workflows with UpBrains AI
      </div>
      <ng-container *ngIf="monthYearStatus === 'year'; else monthlyDescription">
        <div
          class="ap-bg-[#ECFDF3] ap-py-2 ap-px-6 ap-text-[#039855] ap-font-normal ap-rounded-3xl ap-mt-2"
        >
          Get <span class="ap-font-semibold">20% off</span> with a
          <span class="ap-font-semibold">yearly plan!</span>
          <ng-container *ngIf="(allUserSubscriptions$ | async)?.length === 0">
            Try for free for <span class="ap-font-semibold">14 days</span>!
          </ng-container>
        </div>
      </ng-container>

      <ng-template #monthlyDescription>
        <ng-container *ngIf="(allUserSubscriptions$ | async)?.length === 0">
          <div
            class="ap-bg-[#ECFDF3] ap-py-2 ap-px-6 ap-text-[#039855] ap-font-normal ap-rounded-3xl ap-mt-2"
          >
            Try for free for <span class="ap-font-semibold">14 days</span>!
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div
      class="ap-relative ap-flex ap-flex-row ap-justify-between ap-bg-[#F2F4F7] ap-p-[6px] ap-mt-7 ap-mb-14 ap-h-[56px] ap-w-[327px] ap-rounded-2xl ap-cursor-pointer monthly-yearly-container"
    >
      <div
        (click)="updateMonthYearStatus('month')"
        class="ap-w-[157px] ap-h-[44px] ap-bg-[#F2F4F7] ap-flex ap-justify-center ap-items-center ap-font-semibold ap-text-sm"
      >
        Monthly
      </div>
      <div
        (click)="updateMonthYearStatus('year')"
        class="ap-w-[157px] ap-h-[44px] ap-bg-[#F2F4F7] ap-flex ap-justify-center ap-items-center ap-font-semibold ap-text-sm"
      >
        Yearly
      </div>
      <div
        (click)="
          updateMonthYearStatus(monthYearStatus === 'month' ? 'month' : 'year')
        "
        class="ap-w-[157px] ap-h-[44px] ap-bg-[#FFFFFF] ap-flex ap-justify-center ap-items-center ap-font-semibold ap-text-sm ap-text-[#1570EF] ap-absolute ap-rounded-xl"
        [ngStyle]="{ left: monthYearStatus === 'month' ? '6px' : '163px' }"
      >
        {{ monthYearStatus === 'month' ? 'Monthly' : 'Yearly' }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <app-subscription-skeleton />
  </ng-container>

  <div class="ap-flex ap-flex-wrap ap-justify-center ap-items-center ap-gap-4">
    <ng-container *ngIf="subscriptionPlansList$ | async as subscriptionPlans">
      <ng-container *ngFor="let subscriptionPlan of subscriptionPlans">
        <ap-subscription-plan
          *ngIf="allUserSubscriptions$ | async as allUserSubscriptions"
          [planData]="subscriptionPlan"
          [monthYearStatus]="monthYearStatus"
          [subscriptionName]="planName"
          [packagesList]="subscriptionPackagesList"
          [onNextStep]="onNextStep"
          [allUserSubscriptions]="allUserSubscriptions"
        />
      </ng-container>
    </ng-container>
  </div>
</div>
