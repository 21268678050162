import { Injectable } from '@angular/core';
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  updateConsentStatus(consentType: 'granted' | 'denied'): void {
    this.removeExistingScript();

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=GTM-XXXXXXX';
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };

      gtag('js', new Date());
      gtag('consent', 'default', {
        ad_storage: consentType,
        analytics_storage: consentType,
      });

      gtag('consent', 'update', {
        ad_storage: consentType,
        analytics_storage: consentType,
      });
    };
  }

  private removeExistingScript(): void {
    const existingScript = document.querySelector(
      'script[src="https://www.googletagmanager.com/gtag/js?id=GTM-XXXXXXX"]'
    );
    if (existingScript) {
      existingScript.remove();
    }

    window.dataLayer = [];
  }
}
