import { Component, Input } from '@angular/core';
import { Attachment } from '@upbrains/shared';
//Todo: need refactor 
@Component({
  selector: 'ap-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent {
  @Input() attachment: Attachment;

  iconUrl = {
    pdf: 'assets/img/custom/attachments/pdf.svg',
    jpg: 'assets/img/custom/attachments/png.svg',
    txt: 'assets/img/custom/attachments/txt.svg',
    unknown: 'assets/img/custom/attachments/unknown.svg',
  };

  checkFileType(fileType: string) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
    const pdfExtensions = ['pdf'];
    const textExtensions = ['txt', 'md', 'rtf', 'html', 'xml'];

    if (imageExtensions.includes(fileType)) {
      return 'jpg';
    } else if (pdfExtensions.includes(fileType)) {
      return 'pdf';
    } else if (textExtensions.includes(fileType)) {
      return 'txt';
    } else {
      return 'unknown';
    }
  }
}
