import { Static, Type } from '@sinclair/typebox'
import { AllUserSubscriptionsType } from './dto/plans-info'

export const SubscriptionPlans = Type.Object({
    billing_period: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    fixed_amount: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    plan_name: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    stripe_price_id: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    subscription_credit_limit: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
    subscription_quantity_limit: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
    subscription_credit: Type.Optional(
        Type.Union([Type.Number(), Type.Null()]),
    ),
    subscription_quantity: Type.Optional(
        Type.Union([Type.Number(), Type.Null()]),
    ),
    subscription_id: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    subscription_period: Type.Optional(
        Type.Union([Type.Number(), Type.Null()]),
    ),
    subscription_total_price: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
    onboarding_package_stripe_id: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
    should_update: Type.Optional(Type.Union([Type.Boolean(), Type.Null()])),
    oneTimeCharge: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    allUserSubscriptions: Type.Optional(
        Type.Union([
            Type.Array(AllUserSubscriptionsType),
            Type.Null(),
            Type.Undefined(),
        ]),
    ),
})

export type SubscriptionPlansType = Static<typeof SubscriptionPlans>
