<div class="box">
  <div
    class="ap-relative ap-flex ap-flex-row ap-justify-between ap-items-center ap-h-[80px] ap-z-50 ap-shadow-[0px_1px_0px_#f0f0f0] ap-bg-[#f5f5f5]"
  >
    <div
      class="ap-absolute ap-w-full ap-h-full ap-left-0 ap-top-0 ap-flex ap-items-center ap-justify-center ap-p-3 ap-pointer-events-none"
    >
      <div class="ap-h-[30px]">
        <img
          [src]="logoSrc || 'assets/img/custom/logo/full-logo.svg'"
          class="ap-max-w-full ap-h-full"
        />
      </div>
    </div>

    <div
      class="ap-flex-1 ap-flex ap-flex-row ap-justify-start ap-items-center ap-gap-2"
    >
      <ap-icon-button
        [width]="20"
        [height]="20"
        iconUrl="assets/img/custom/dashboard/home.svg"
        tooltipText="Home"
        i18n-tooltipText
        (buttonClicked)="redirectHome(false)"
        (auxclick)="redirectHome(true)"
      ></ap-icon-button>
      <div class="ap-flex ap-flex-row ap-gap-2">
        <div
          class="mat-mdc-tooltip-trigger ap-typography-body-1 ap-truncate ap-max-w-[150px] ap-cursor-pointer hover:ap-text-[#000000] ng-tns-c2280036445-12 ng-star-inserted"
          (click)="redirectHome(false)"
          (auxclick)="redirectHome(true)"
        >
          Forms
        </div>
        <div
          class="ap-typography-body-1 ap-text-placeholder ng-tns-c2280036445-12 ng-star-inserted"
        >
          /
        </div>
        <div
          class="mat-mdc-tooltip-trigger !ap-font-semibold ap-typography-body-1 viewed-text-container ng-star-inserted"
        >
          {{ title }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="ap-w-full ap-flex ap-items-center ap-justify-start ap-bg-center ap-bg-cover ap-flex-col ap-w-[100vw] ap-h-[92vh] ap-p-10 lg:ap-justify-center"
  >
    <div
      class="ap-w-full ap-flex ap-flex-col ap-justify-center ap-items-start ap-gap-10 lg:ap-flex-row"
    >
      <div
        class="ap-flex-1 ap-overflow-y-auto ap-max-h-[80vh] ap-bg-white ap-shadow-xl ap-rounded-md ap-w-full lg:ap-min-w-[750px]"
      >
        <form
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          class="ap-flex ap-flex-col ap-gap-2 ap-w-full"
        >
          <mat-card class="auth-card ap-overflow-scroll">
            <div class="ap-typography-headline-5 ap-mb-6">
              {{ title }}
            </div>
            <div
              class="ap-flex ap-flex-col ap-gap-3 ap-w-full lg:ap-grid"
              [ngClass]="gridColumns"
            >
              <ng-container *ngFor="let input of inputs">
                <ng-container [ngSwitch]="input.type">
                  <!-- Short Text input -->
                  <ng-container *ngSwitchCase="PropertyType.SHORT_TEXT">
                    <ng-container *ngIf="!input.hide">
                      <mat-form-field
                        class="ap-w-full"
                        appearance="outline"
                        subscriptSizing="dynamic"
                      >
                        <mat-label>{{ input.displayName }}</mat-label>
                        <input
                          matInput
                          type="text"
                          [formControlName]="input.uniqueKey"
                        />
                        <mat-hint>{{ input.description }}</mat-hint>
                        <mat-error
                          *ngIf="form?.controls?.[input.uniqueKey]?.invalid"
                        >
                          {{ input.displayName }} is required
                        </mat-error>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <!-- File input -->
                  <ng-container *ngSwitchCase="PropertyType.FILE">
                    <ng-container *ngIf="!input.hide">
                      <mat-form-field
                        class="ap-w-full"
                        subscriptSizing="dynamic"
                      >
                        <mat-label i18n>{{ input.displayName }}</mat-label>
                        <ap-file-upload
                          [required]="input.required"
                          #flowInput
                          [formControlName]="input.uniqueKey"
                          [extensions]="['.*']"
                        ></ap-file-upload>
                        <ap-icon-button
                          [height]="25"
                          [width]="25"
                          iconFilename="attach.svg"
                          matSuffix
                          (buttonClicked)="
                            flowInput.fileInput.nativeElement.click()
                          "
                        ></ap-icon-button>
                        <mat-hint>{{ input.description }}</mat-hint>
                        <mat-error
                          *ngIf="form?.controls?.[input.uniqueKey]?.invalid"
                        >
                          {{ input.displayName }} is required
                        </mat-error>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <!-- Checkbox input -->
                  <ng-container *ngSwitchCase="PropertyType.CHECKBOX">
                    <ng-container *ngIf="!input.hide">
                      <div class="app-w-full">
                        <mat-slide-toggle
                          [formControlName]="input.uniqueKey"
                          color="primary"
                          i18n
                        >
                          {{ input.displayName }}
                        </mat-slide-toggle>
                        <markdown class="ap-typography-caption">
                          {{ input.description }}
                        </markdown>
                        <mat-error
                          *ngIf="form?.controls?.[input.uniqueKey]?.invalid"
                          class="ap-mt-2"
                        >
                          {{ input.displayName }} is required
                        </mat-error>
                      </div>
                    </ng-container>
                  </ng-container>

                  <!-- Long Text input -->
                  <ng-container *ngSwitchCase="PropertyType.LONG_TEXT">
                    <ng-container *ngIf="!input.hide">
                      <mat-form-field
                        class="ap-w-full"
                        appearance="outline"
                        subscriptSizing="dynamic"
                      >
                        <mat-label>{{ input.displayName }}</mat-label>
                        <textarea
                          matInput
                          [formControlName]="input.uniqueKey"
                        ></textarea>
                        <mat-hint>{{ input.description }}</mat-hint>
                        <mat-error
                          *ngIf="form?.controls?.[input.uniqueKey]?.invalid"
                        >
                          {{ input.displayName }} is required
                        </mat-error>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <!-- Display Text input -->
                  <ng-container *ngSwitchCase="PropertyType.DISPLAY_TEXT">
                    <ng-container *ngIf="!input.hide">
                      <div class="ap-w-full">
                        <div
                          *ngIf="
                            (input?.value?.length ?? 0) <= 20;
                            else longText
                          "
                          class="ap-typography-body-1"
                        >
                          <strong class="ap-font-bold"
                            >{{ input.displayName }}:</strong
                          >
                          {{ input.value }}
                        </div>
                      </div>
                      <ng-template #longText>
                        <div class="ap-w-full">
                          <div class="ap-typography-body-1">
                            <strong class="ap-font-bold"
                              >{{ input.displayName }}:</strong
                            >
                          </div>
                          <div class="ap-typography-body-1">
                            <ap-markdown
                              [fullWidth]="true"
                              [data]="convertToString(input.value)"
                              class="ap-mt-4"
                            ></ap-markdown>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </ng-container>

                  <!-- Select input -->
                  <ng-container *ngSwitchCase="'SELECT'">
                    <ng-container *ngIf="!input.hide">
                      <mat-form-field class="ap-w-full">
                        <mat-label>{{ input.displayName }}</mat-label>
                        <mat-select [formControlName]="input.uniqueKey">
                          <!-- Ensure input.value is defined and a string before splitting -->
                          <mat-option
                            *ngFor="let option of getOptions(input.value)"
                            [value]="option"
                            i18n
                            >{{ option }}</mat-option
                          >
                        </mat-select>
                        <mat-hint>{{ input.description }}</mat-hint>
                        <mat-error
                          *ngIf="form?.controls?.[input.uniqueKey]?.invalid"
                        >
                          {{ input.displayName }} is required
                        </mat-error>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <!-- Custom Action Button -->
                  <ng-container *ngSwitchCase="PropertyType.CUSTOM_ACTION">
                    <ng-container *ngIf="!input.hide">
                      <div class="ap-flex ap-justify-between">
                        <ap-button
                          [loading]="loading"
                          (buttonClicked)="openLinkInNewTab(input.value)"
                          btnColor="primary"
                          btnSize="large"
                          [fullWidthOfContainer]="true"
                          i18n
                        >
                          {{ input.displayName }}
                        </ap-button>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

            <ng-container *ngIf="hasApprovalOrDisapproval">
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-end ap-gap-6 ap-mt-8 ap-w-[100%] ap-mx-auto ap-mt-4 lg:ap-mx-[unset]"
              >
                <ng-container
                  *ngFor="
                    let approveAndDisapproveButtons of getSerializedApprovalButtons()
                      | async
                  "
                >
                  <ng-container
                    *ngIf="
                      !approveAndDisapproveButtons.hide &&
                      !approveAndDisapproveButtons.approve.hide
                    "
                  >
                    <div class="ap-flex ap-flex-col ap-gap-3 ap-w-full">
                      <div
                        class="ap-font-bold"
                        [ngClass]="{
                        'ap-text-green-500':
                          approveAndDisapproveButtons?.output?.['approved'],
                        'ap-text-red-500':
                          approveAndDisapproveButtons?.output &&
                          !approveAndDisapproveButtons?.output?.['approved'],
                        'ap-text-black': !approveAndDisapproveButtons?.output
                      }"
                      >
                        {{ approveAndDisapproveButtons.approve.displayName }}
                        {{
                        approveAndDisapproveButtons?.output
                          ? approveAndDisapproveButtons.output?.['approved']
                            ? 'Approved'
                            : 'Rejected'
                          : 'Approval'






                        }}:
                      </div>
                      <ng-container
                        *ngIf="!approveAndDisapproveButtons?.output"
                      >
                        <mat-form-field
                          class="ap-w-full"
                          appearance="outline"
                          subscriptSizing="dynamic"
                        >
                          <mat-label>{{
                            approveAndDisapproveButtons.approve.displayName +
                              '
                          Approval Comment'
                          }}</mat-label>
                          <input
                            matInput
                            type="text"
                            [formControlName]="
                              approveAndDisapproveButtons.approve.uniqueKey +
                              '_comment'
                            "
                            placeholder="Enter your comment"
                          />
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="approveAndDisapproveButtons?.output">
                        <div class="ap-font-bold">
                          {{
                            approveAndDisapproveButtons.approve.displayName +
                              'Approval Comment'
                          }}:
                          <span
                            class="ap-font-normal ap-bg-gray-300 ap-px-2 ap-py-1 ap-rounded"
                          >
                            {{
                            approveAndDisapproveButtons.output?.['body']?.comment ||
                              'No Comment'
                            }}
                          </span>
                        </div>
                        <div class="ap-font-bold">
                          {{
                          approveAndDisapproveButtons.output?.['approved']
                            ? 'Approved by'
                            : 'Rejected by'
                          }}
                          :
                          <span
                            class="ap-font-normal ap-bg-gray-300 ap-px-2 ap-py-1 ap-rounded"
                            >{{
                            approveAndDisapproveButtons.output?.['body']?.user
                              ? approveAndDisapproveButtons.output?.['body']?.user
                                  .userEmail
                              : ''
                            }}</span
                          >
                        </div>
                        <div class="ap-font-bold">
                          {{
                          approveAndDisapproveButtons.output?.['approved']
                            ? 'Approved at'
                            : 'Rejected at'
                          }}
                          :
                          <span
                            class="ap-font-normal ap-bg-gray-300 ap-px-2 ap-py-1 ap-rounded"
                            >{{
                            approveAndDisapproveButtons.output?.['body']?.timestamp
                              ? formatTimestamp(
                                  approveAndDisapproveButtons.output?.['body']
                                    ?.timestamp
                                )
                              : ''
                            }}</span
                          >
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="!approveAndDisapproveButtons?.output"
                      >
                        <div
                          class="ap-flex ap-gap-2 ap-w-full ap-justify-start"
                        >
                          <ap-button
                            type="button"
                            [loading]="
                              buttonLoading[
                                approveAndDisapproveButtons.approve.uniqueKey
                              ]
                            "
                            [disabled]="
                              disabledButtons.includes(
                                approveAndDisapproveButtons.approve.uniqueKey
                              ) ||
                              disabledButtonsByClicked.includes(
                                approveAndDisapproveButtons.approve.uniqueKey
                              )
                            "
                            (buttonClicked)="
                              openConfirmationDialog(
                                'approve',
                                approveAndDisapproveButtons.approve.uniqueKey,
                                approveAndDisapproveButtons.approve.value
                              )
                            "
                            btnColor="primary"
                            btnSize="large"
                          >
                            Approve
                          </ap-button>
                          <ap-button
                            type="button"
                            [loading]="
                              buttonLoading[
                                approveAndDisapproveButtons.disapprove.uniqueKey
                              ]
                            "
                            [disabled]="
                              disabledButtons.includes(
                                approveAndDisapproveButtons.disapprove.uniqueKey
                              ) ||
                              disabledButtonsByClicked.includes(
                                approveAndDisapproveButtons.disapprove.uniqueKey
                              )
                            "
                            (buttonClicked)="
                              openConfirmationDialog(
                                'disapprove',
                                approveAndDisapproveButtons.disapprove
                                  .uniqueKey,
                                approveAndDisapproveButtons.disapprove.value
                              )
                            "
                            btnColor="warn"
                            btnSize="large"
                          >
                            Reject
                          </ap-button>
                        </div>
                        <mat-error *ngIf="error">
                          {{ error }}
                        </mat-error>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>

            <!-- Submit Button -->
            <ng-container *ngIf="showSubmitButton">
              <div
                class="text-center ap-mt-6 ap-flex ap-flex-col ap-gap-2 ap-w-full xl:ap-w-[200px] xl:ap-ml-auto xl:ap-mt-8"
              >
                <ap-button
                  [loading]="loading"
                  [disabled]="!(checkSubmitButtonCondition() | async)"
                  (buttonClicked)="onSubmit()"
                  btnColor="primary"
                  btnSize="large"
                  [fullWidthOfContainer]="true"
                  i18n
                >
                  {{ submitAction?.displayName || 'Submit' }}
                </ap-button>
              </div>
            </ng-container>

            <!-- Markdown Response -->
            <ng-container *ngIf="markdownResponse | async as mkResponse">
              <ap-markdown
                [fullWidth]="true"
                [data]="mkResponse"
                class="ap-mt-4 ap-mb-4"
              ></ap-markdown>
            </ng-container>
          </mat-card>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Error Display -->

<!-- Powered By ActivePieces -->
<!-- <ap-powered-by-activepieces class="ap-mt-4"></ap-powered-by-activepieces> -->

<!-- Additional ng-container for flow and submitForm -->
<!-- <ng-container *ngIf="flow$ | async as flow"></ng-container>
<ng-container *ngIf="submitForm$ | async"></ng-container> -->
