import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema } from '../../common'

export const CaseTemplate = Type.Object({
    ...BaseModelSchema,
    projectId: Type.String(),
    caseType: Type.String(),
    caseFunctionName: Type.String(),
    caseIdentifierKeys: Type.Array(Type.String()),
})

export const CaseTemplateCreationPayload = Type.Omit(
    CaseTemplate,
    [
        'id',
        'created',
        'updated',
        'projectId',
    ],
)

export const CaseTemplateUpdatePayload = Type.Partial(CaseTemplateCreationPayload)

export type CaseTemplate = Static<typeof CaseTemplate>
export type CaseTemplateCreationPayload = Static<typeof CaseTemplateCreationPayload>
export type CaseTemplateUpdatePayload = Static<typeof CaseTemplateUpdatePayload>