import { Component, Input } from '@angular/core';

interface ActionButtonConfig {
  title: string;
  onClick: () => void;
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
})
export class PageHeaderComponent {
  @Input({ required: true }) header: string;
  @Input() description?: string;
  @Input() actionButtonConfig?: ActionButtonConfig;

  constructor() {}

  onActionButtonClick(): void {
    if (this.actionButtonConfig) {
      this.actionButtonConfig.onClick();
    }
  }
}
