/// TODO: work on this page for testing
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  // AuthenticationService,
  StepSliderConfig,
} from '@upbrains/ui/common';
import { Subscription } from 'rxjs';
// import { DeclareStepsComponent } from '../../steps/declare-steps/declare-steps.component';
// import { CompanyUrlComponent } from '../../steps/company-url/company-url.component';
// import { ExtractedInsightsComponent } from '../../steps/extracted-insights/extracted-insights.component';
import { SubscriptionPlansComponent } from '../../steps/subscription-plans/subscription-plans.component';
import { SubscribePlanComponent } from '../../steps/subscribe-plan/subscribe-plan.component';
// import { HealthAgentConnectionComponent } from '../../steps/health-agent-connection/health-agent-connection.component';
// import { HealthAgentLoadingComponent } from '../../steps/health-agent-loading/health-agent-loading.component';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
})
export class OnboardingComponent implements OnInit, OnDestroy {
  stepsConfig: StepSliderConfig;
  isManual = false;
  private queryParamSubscription: Subscription | undefined;

  constructor(
    // private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.stepsConfig = {
      title: '',
      subTitle: '',
      onClose: () => undefined,
      onPrevStep: () => undefined,
      onNextStep: () => undefined,
      steps: {},
    };
  }

  ngOnInit() {
    // const { firstName } = this.authenticationService.currentUser;

    this.queryParamSubscription = this.route.queryParamMap.subscribe(
      (params) => {
        this.isManual = params.get('isManual') === 'true';

        this.stepsConfig = {
          title: 'Onboarding Agent',
          onClose: () => undefined,
          onPrevStep: () => undefined,
          steps: {
            // 0: {
            //   message: `Hi there, ${firstName}\n Let's complete these steps together`,
            //   component: DeclareStepsComponent,
            // },
            // 1: {
            //   message: `Provide your company URL\n This gives me useful information for workspace`,
            //   component: CompanyUrlComponent,
            //   isBackNavigationAllowed: true,
            // },
            // 2: {
            //   message: this.isManual
            //     ? 'Give me valuable information about yourself'
            //     : `I have extracted insights about you`,
            //   component: ExtractedInsightsComponent,
            //   isBackNavigationAllowed: true,
            // },
            0: {
              message: ``,
              component: SubscriptionPlansComponent,
              noTitle: true,
            },
            1: {
              message: ``,
              component: SubscribePlanComponent,
              isBackNavigationAllowed: true,
              noTitle: true,
            },
            // 5: {
            //   customTitle: 'Inbox Health Agent',
            //   customIcon: 'assets/img/newDesign/onboarding/health-agent.svg',
            //   message: `Hi ${firstName}, I am your Inbox Health Agent\n I can review your inbox and prepare inbox health report for free`,
            //   component: HealthAgentConnectionComponent,
            //   isBackNavigationAllowed: true,
            //   canSkip: true,
            // },
            // 6: {
            //   customTitle: 'Inbox Health Agent',
            //   customIcon: 'assets/img/newDesign/onboarding/health-agent.svg',
            //   message: `This might take a few minutes\n You can continue on onboarding while I am working on your inbox`,
            //   component: HealthAgentLoadingComponent,
            //   isBackNavigationAllowed: true,
            // },
          },
        };
      }
    );
  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }
}
