<div
  class="ap-relative subscribe-plan ap-w-[480px] ap-mx-auto ap-flex ap-flex-col ap-justify-between ap-items-center ap-font-inter ap-text-[#344054]"
>
  <div class="ap-font-bold ap-text-3xl">Subscribe to {{ planName }} plan</div>

  <div
    class="ap-w-full ap-rounded-[20px] ap-bg-[#F8F9FB] ap-border-[1px] ap-border-[#D6DBF5] ap-py-4 ap-px-6 ap-my-4 ap-flex ap-flex-col"
  >
    <div>
      <span class="ap-font-bold ap-text-4xl ap-text-[#344054]"
        >${{ subscriptionPlanInfo?.subscription_total_price }}</span
      >
      <span class="ap-font-normal ap-text-base ap-text-[#667085]"
        >/ {{ subscriptionPlanInfo?.billing_period }}</span
      >
    </div>
    <mat-list role="list" class="ap-flex ap-flex-col custom-list">
      <mat-list-item
        *ngFor="let item of subscribeItems"
        role="listitem"
        class="!ap-h-auto !ap-px-0 !ap-py-1 custom-list-item"
      >
        <div class="ap-flex ap-items-center ap-mt-2">
          <svg-icon
            src="assets/img/custom/subscription/check.svg"
            class="ap-w-3 ap-h-3 ap-mr-3"
            [applyClass]="true"
          ></svg-icon>
          <span
            class="ap-flex-1 ap-break-words feature-description-style ap-text-sm ap-font-normal"
            [innerHTML]="sanitizeHtml(item)"
          >
          </span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

  <ng-container *ngIf="!subscriptionPlanInfo?.should_update">
    <form id="payment-form" [formGroup]="paymentForm" class="ap-w-full">
      <div class="ap-text-sm ap-font-medium ap-mr-auto ap-mt-1">
        Name On the CARD
      </div>
      <mat-form-field class="ap-w-full ap-font-inter" subscriptSizing="dynamic">
        <input
          type="text"
          matInput
          class="ap-outline-none ap-w-full"
          formControlName="userName"
          placeholder="E.g. John Doe"
        />
        <mat-error *ngIf="userName?.hasError('required')">
          Name is required
        </mat-error>
        <mat-error *ngIf="userName?.hasError('minlength')">
          Name must be at least 3 characters long
        </mat-error>
      </mat-form-field>

      <div class="ap-text-sm ap-font-medium ap-mr-auto ap-mt-9">Card</div>
      <div
        id="card-element"
        class="ap-border ap-border-[#D0D5DD] ap-rounded-lg ap-h-[48px] ap-appearance-none ap-block ap-w-full ap-pt-[12px] ap-px-2 ap-leading-tight ap-focus:outline-none ap-focus:bg-white"
      >
        <!-- A Stripe Element will be inserted here. -->
      </div>
      <div
        id="card-element-errors"
        class="text-brown text-base mt-2"
        role="alert"
        [ngClass]="isFocused ? 'focused' : ''"
      ></div>

      <ap-button
        actionButton
        btnSize="large"
        i18n
        class="ap-w-full ap-mt-9"
        [fullWidthOfContainer]="true"
        type="submit"
        [loading]="isLoading"
        (buttonClicked)="handlePayment()"
      >
        <div
          class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
        >
          {{ subscribeButtonContent }}
        </div>
      </ap-button>
    </form>
  </ng-container>

  <ng-container *ngIf="subscriptionPlanInfo?.should_update">
    <ap-button
      actionButton
      btnSize="large"
      i18n
      class="ap-w-full ap-mt-9"
      [fullWidthOfContainer]="true"
      type="submit"
      [loading]="isLoading"
      (buttonClicked)="handleUpdating()"
      [disabled]="isLoading"
    >
      <div
        class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
      >
        Submit
      </div>
    </ap-button>
  </ng-container>
</div>
