<div class="ap-flex ap-flex-wrap ap-justify-center ap-items-center ap-gap-4">
  <ng-container *ngFor="let item of [1, 2, 3]">
    <div
      class="ap-flex ap-flex-col ap-border ap-rounded-2xl ap-p-5 ap-text-[#344054] ap-font-inter ap-max-w-[305px] md:ap-h-[490px] ap-h-auto subscription-plan"
    >
      <div class="ap-mb-[6px]">
        <ngx-skeleton-loader [theme]="{ height: '28px', width: '160px' }" />
      </div>

      <div class="ap-my-[6px">
        <ngx-skeleton-loader [theme]="{ height: '28px', width: '130px' }" />
      </div>

      <div class="ap-mt-[17px]">
        <ngx-skeleton-loader [theme]="{ height: '28px', width: '145px' }" />
      </div>

      <div class="ap-mt-[23px]">
        <ngx-skeleton-loader [theme]="{ height: '48px', width: '100%' }" />
      </div>

      <div class="ap-mt-[23px]">
        <ngx-skeleton-loader [theme]="{ height: '28px', width: '145px' }" />
      </div>

      <div class="ap-flex ap-flex-col ap-mt-[23px]">
        <ngx-skeleton-loader [theme]="{ height: '28px', width: '145px' }" />
        <ngx-skeleton-loader [theme]="{ height: '28px', width: '125px' }" />
        <ngx-skeleton-loader [theme]="{ height: '28px', width: '100px' }" />
      </div>
    </div>
  </ng-container>
</div>
