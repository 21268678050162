<div class="ap-w-full ap-flex ap-items-start ap-justify-between">
  <div class="">
    <h2
      class="ap-text-2xl ap-font-semibold ap-text-[#101828] ap-text-3xl ap-font-semibold"
    >
      {{ header }}
    </h2>
    <p *ngIf="description" class="ap-text-base ap-text-[#475467]">
      {{ description }}
    </p>
  </div>

  <div *ngIf="actionButtonConfig">
    <ap-button
      (buttonClicked)="onActionButtonClick()"
      btnSize="medium"
      btnColor="primary"
      i18n
    >
      {{ actionButtonConfig.title }}
    </ap-button>
  </div>
</div>
