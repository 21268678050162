import { Static, Type } from '@sinclair/typebox'

export const ListProjectCasesParamDto = Type.Object({
    projectId: Type.String({}),
})

export type ListProjectCasesParamDto = Static<typeof ListProjectCasesParamDto>

export const ProjectCaseItemParamDto = Type.Object({
    projectId: Type.String({}),
    caseId: Type.String({}),
})
