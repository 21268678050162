import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import { SubscriptionPlansType } from '@upbrains/shared';
import {
  AuthenticationService,
  NavigationService,
  SnackbarService,
  SubscriptionService,
} from '../../service';
import * as CryptoJS from 'crypto-js';

type SubscriptionPlansWithStatusType = SubscriptionPlansType & {
  currentPlanStatus?: string | null;
};
@Component({
  selector: 'ap-subscribe-plan',
  templateUrl: './subscribe-plan.component.html',
  styleUrls: ['./subscribe-plan.component.scss'],
})
export class SubscribePlanComponent implements OnInit {
  stripe: Stripe | null = null; // Stripe instance
  elements: StripeElements | null = null; // Elements instance
  cardElement: any; // Stripe Card Element reference
  isFocused: boolean = false;
  stripeError: string = '';
  subscribeButtonContent: string = 'Subscribe';
  isLoading: boolean = false;
  publishableKey: string | null = null;
  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  subscriptionPlan$: Observable<
    SubscriptionPlansWithStatusType | undefined | null
  >;
  subscriptionPlanInfo: SubscriptionPlansWithStatusType | undefined | null;
  planName: string | undefined | null = '';
  subscribeItems: string[] = [];
  paymentForm!: FormGroup;
  currentPlanStatus: string | undefined | null;

  constructor(
    private navigationService: NavigationService,
    private sanitizer: DomSanitizer,
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initializeStripe();

    this.paymentForm = this.fb.group({
      userName: ['', [Validators.required, Validators.minLength(3)]],
    });

    this.route.queryParams.subscribe((params) => {
      const hashedPlan = params['plan'];
      if (hashedPlan) {
        // Decrypt the hashed plan
        try {
          const bytes = CryptoJS.AES.decrypt(
            hashedPlan,
            this.authenticationService.getDecodedIgniteToken()
          ); // Use the same secret key
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          this.subscriptionPlanInfo = JSON.parse(decryptedData);
          this.planName = this.subscriptionPlanInfo?.plan_name;
          this.currentPlanStatus = this.subscriptionPlanInfo?.currentPlanStatus;
          const customPeriod = this.subscriptionPlanInfo?.billing_period
            ?.toLocaleLowerCase()
            ?.includes('month')
            ? 'Monthly'
            : 'Yearly';
          this.subscribeItems = [
            `Subscribing to <span class="ap-font-semibold ap-text-base">${this.planName}</span>`,
            `Billed <span class="ap-font-semibold ap-text-base">${customPeriod}</span>`,
            `Total due: <span class="ap-font-semibold ap-text-base">$${Number(
              this.subscriptionPlanInfo?.subscription_total_price
            )?.toFixed(2)}</span>`,
            `${customPeriod} automation credits: <span class="ap-font-semibold ap-text-base">${this.subscriptionPlanInfo?.subscription_credit}</span>`,
            `Number of user seats: <span class="ap-font-semibold ap-text-base">${this.subscriptionPlanInfo?.subscription_quantity}</span>`,
            `Excess credits used are billed <span class="ap-font-semibold ap-text-base">${customPeriod.toLocaleLowerCase()}</span>`,
            `Onboarding Service Package: <span class="ap-font-semibold ap-text-base">$${this.subscriptionPlanInfo?.oneTimeCharge}</span> (one time charge)`,
          ];
        } catch (error) {
          console.error('Error decrypting plan:', error);
        }
      }
    });
  }

  redirectToHome(newWindow: boolean) {
    this.navigationService.navigate('/', newWindow);
  }

  redirectToPlans(newWindow: boolean) {
    this.navigationService.navigate(
      `/subscriptions/plans/${this.planName}`,
      newWindow
    );
  }

  sanitizeHtml(input: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(input);
  }

  async initializeStripe(): Promise<void> {
    try {
      const response: any = await firstValueFrom(
        this.subscriptionService.getStripePublishableKey()
      );

      if (response && response.publishableKey) {
        // Initialize Stripe and elements
        this.stripe = await loadStripe(response.publishableKey);
        if (this.stripe) {
          this.elements = this.stripe.elements();
          const style = {
            base: {
              fontSize: '16px',
              color: '#32325d',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
              fontSmoothing: 'antialiased',
              '::placeholder': {
                color: '#a0aec0',
              },
            },
          };
          this.cardElement = this.elements.create('card', { style });
          this.cardElement.mount('#card-element'); // Mount the card element into the div

          this.cardElement.on('focus', () => {
            this.isFocused = true;
          });
          this.cardElement.on('blur', () => {
            this.isFocused = false;
          });
          this.cardElement.on('change', (event: any) => {
            this.displayError(event);
          });
        }
      }
    } catch (error) {
      console.error('Error fetching the Stripe key:', error);
    }
  }

  displayError(event: any) {
    if (event.error) {
      this.stripeError = event.error.message;
      this.changeSubmissionLoadingState(false);
    } else {
      this.stripeError = '';
    }
  }

  changeSubmissionLoadingState(isLoading: boolean) {
    if (isLoading) {
      this.subscribeButtonContent = 'Processing Subscription ...';
      this.isLoading = true;
    } else {
      this.subscribeButtonContent = 'Subscribe';
      this.isLoading = false;
    }
  }

  get userName() {
    return this.paymentForm.get('userName');
  }

  async createSubscription({
    paymentMethodId,
    stripePriceId,
    period,
    userSeats,
    credits,
    onboardingPackage,
    customerId,
  }: {
    paymentMethodId: string | undefined | null;
    stripePriceId: string | undefined | null;
    period: string | undefined | null;
    userSeats: number | undefined | null;
    credits: number | undefined | null;
    onboardingPackage: string | undefined | null;
    customerId: string;
  }) {
    try {
      const { email } = this.authenticationService.currentUser;
      const addon_services: any = [];
      var addon_services_json = JSON.stringify(addon_services);
      const payload = {
        customerId: customerId,
        paymentMethodId,
        priceId: stripePriceId,
        quantity: userSeats,
        additional_credits: credits,
        trial_period_days: 0,
        identifier: email,
        addon_services: addon_services_json,
        onboardingPackage: onboardingPackage,
      };

      const response: any = await firstValueFrom(
        this.subscriptionService.createSubscription(payload)
      );

      this.changeSubmissionLoadingState(false);
      if (response) {
        this.snackbarService.showSuccess(
          'Your subscription created successfully!'
        );

        setTimeout(() => {
          this.router.navigate(['/subscriptions']);
        }, 2000);
      }
      return response;
    } catch (err) {
      this.changeSubmissionLoadingState(false);
      this.snackbarService.showError(
        'There was a problem in creating subscription!'
      );
      throw new Error(err.message);
    }
  }

  async createPaymentMethod({ isPaymentRetry, invoiceId }: any): Promise<void> {
    const { email } = this.authenticationService.currentUser;

    try {
      const customer: any = await firstValueFrom(
        this.subscriptionService.createCustomer({ email })
      );
      const customerId = customer?.customerId;
      const result: any = await this.stripe?.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        billing_details: {
          name: this.paymentForm.get('userName')?.value,
        },
      });

      if (result.error) {
        this.snackbarService.showError(
          'Not valid card number. Please check the card details and try again!'
        );
        this.displayError(result.error);
      } else {
        if (isPaymentRetry) {
          // Update the payment method and retry invoice payment
          const retryPayload = {
            customerId: customerId,
            paymentMethodId: result.paymentMethod.id,
            invoiceId: invoiceId,
            identifier: email,
          };
          await this.subscriptionService
            .retryInvoiceWithNewPaymentMethod(retryPayload)
            .toPromise();
        } else {
          // Create the subscription
          await this.createSubscription({
            paymentMethodId: result.paymentMethod.id,
            stripePriceId: this.subscriptionPlanInfo?.stripe_price_id,
            period: String(this.subscriptionPlanInfo?.subscription_period),
            userSeats: this.subscriptionPlanInfo?.subscription_quantity,
            credits: this.subscriptionPlanInfo?.subscription_credit,
            onboardingPackage:
              this.subscriptionPlanInfo?.onboarding_package_stripe_id,
            customerId: customerId ?? customer.customer.id,
          });
        }
      }
    } catch (error) {
      console.error('Error processing payment method:', error);
    }
  }

  async handlePayment() {
    if (!this.stripe || !this.cardElement) return;

    if (this.paymentForm.invalid) {
      // Handle invalid form
      console.error('Form is invalid');
      return;
    }

    this.changeSubmissionLoadingState(true);

    // get latestInvoicePaymentIntentStatus
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    ); // get from localstorage
    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      const invoiceId = 'latest_invoice_id'; // get from localstorage
      const isPaymentRetry = true;
      // create new payment method & retry payment on invoice with new payment method
      this.createPaymentMethod({
        isPaymentRetry,
        invoiceId,
      });
    } else {
      // create new payment method & create subscription
      this.createPaymentMethod({});
    }
  }

  async handleUpdating() {
    try {
      this.changeSubmissionLoadingState(true);
      const payload = {
        existing_seats_price_id: this.subscriptionPlanInfo?.stripe_price_id,
        new_additional_credits: String(
          this.subscriptionPlanInfo?.subscription_credit
        ),
        new_seats_price_id: this.subscriptionPlanInfo?.stripe_price_id,
        new_user_seats_quantity: String(
          this.subscriptionPlanInfo?.subscription_quantity
        ),
        subscription_id: this.subscriptionPlanInfo?.subscription_id,
        ...(this.currentPlanStatus?.toLowerCase()?.includes('trialing') ||
        this.currentPlanStatus?.toLowerCase()?.includes('trialing')
          ? {
              trial_end: 'now',
            }
          : null),
      };

      const response: any = await firstValueFrom(
        this.subscriptionService.updateSubscription(payload)
      );

      this.changeSubmissionLoadingState(false);
      if (response) {
        this.snackbarService.showSuccess('Your plan updated successfully!');

        setTimeout(() => {
          this.router.navigate(['/subscriptions']);
        }, 2000);
      }
      return response;
    } catch (err) {
      this.changeSubmissionLoadingState(false);
      this.snackbarService.showError(
        'There was a problem in updating the subscription!'
      );
      throw new Error(err.message);
    }
  }
}
