import { createAction, props } from '@ngrx/store';
import {
  ConversationDetails,
  ConversationResponse,
  PayloadMessage,
} from '@upbrains/shared';

export const loadDetails = createAction(
  '[Conversation] Load Details',
  props<{ conversationId: string }>()
);

export const loadDetailsSuccess = createAction(
  '[Conversation] Load Details Success',
  props<{ details: ConversationDetails }>()
);

export const loadMessages = createAction(
  '[Conversation] Load Messages',
  props<{ conversationId: string }>()
);

export const loadMessagesSuccess = createAction(
  '[Conversation] Load Messages Success',
  props<{ messages: ConversationResponse }>()
);

export const selectMessage = createAction(
  '[Conversation] Select Message',
  props<{ selectedMessage: PayloadMessage }>()
);
export const loadSelectedMessageSuccess = createAction(
  '[Conversation] Load Selected Message Success',
  props<{ selectedMessage: PayloadMessage }>()
);
export const loadSelectedMessage = createAction(
  '[Conversation] Load Selected Message',
  props<{ messageId: string }>()
);
export const conversationActions = {
  loadDetails,
  loadDetailsSuccess,
  loadMessages,
  loadMessagesSuccess,
  selectMessage,
  loadSelectedMessageSuccess,
  loadSelectedMessage,
};
