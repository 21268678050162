import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-health-agent-loading',
  templateUrl: './health-agent-loading.component.html',
  styleUrls: ['./health-agent-loading.component.scss'],
})
export class HealthAgentLoadingComponent {
  constructor(private router: Router) {}

  onContinue() {
    this.router.navigate(['/templates']);
  }
}
