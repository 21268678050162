import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AllUserSubscriptionsType,
  PlansInfoObjectType,
} from '@upbrains/shared';
import { SubscriptionService } from '@upbrains/ui/common';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  finalize,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
})
export class SubscriptionPlansComponent implements OnInit, OnDestroy {
  @Input() onNextStep!: () => void;

  subscriptionPlansList$: Observable<PlansInfoObjectType[] | undefined | null> =
    of(null);
  monthYearStatus = 'month';
  private monthYearStatusSubject = new BehaviorSubject<string>(
    'default-status'
  );
  private destroy$ = new Subject<void>();
  subscriptionPackagesList: PlansInfoObjectType[] | undefined | null = [];

  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  planName: string | null = '';
  isLoading = false;
  allUserSubscriptions$: Observable<
    AllUserSubscriptionsType[] | null | undefined
  > = of(undefined);

  constructor(
    private subscriptionService: SubscriptionService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.subscriptionPlansList$ = combineLatest([
      this.subscriptionService.getPlansInfo(),
      this.monthYearStatusSubject,
    ]).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.isLoading = true;
      }),
      map(([plansInfo, status]) => {
        if (!plansInfo?.results.plans_info) {
          return [];
        }

        this.isLoading = false;
        return plansInfo?.results.plans_info
          .filter((plan) =>
            plan.billing_period?.toLocaleLowerCase()?.includes(status)
          )
          .sort((a, b) => (a.fixed_amount ?? 0) - (b.fixed_amount ?? 0));
      }),
      finalize(() => {
        // Set loading to false when the process completes
        this.isLoading = false;
      })
    );

    // Set initial month/year status
    this.monthYearStatusSubject.next(this.monthYearStatus);

    this.allUserSubscriptions$ = this.subscriptionService
      .getAllSubscriptions()
      .pipe(
        map((subscriptions) => subscriptions?.all_user_subscriptions || null)
      );
  }

  loadSubscriptionPlans(): void {
    this.subscriptionService.getPlansInfo().subscribe({
      next: (data) => {
        console.log('Loaded subscription plans:', data);
      },
      error: (err) => {
        console.error('Error loading subscription plans:', err);
      },
    });
  }

  getPlansInfo(): Observable<PlansInfoObjectType[] | null> {
    return this.httpClient.get<any>('/api/subscription-plans').pipe(
      map((response) => {
        return response?.results?.plans_info || null;
      }),
      catchError((error) => {
        console.error('Error fetching subscription plans info:', error);
        return of(null);
      })
    );
  }

  updateMonthYearStatus(newStatus: string): void {
    this.monthYearStatus = newStatus;
    this.monthYearStatusSubject.next(newStatus);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
