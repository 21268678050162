import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  InboxAnalysisResponse,
  PostInboxAnalysisRequest,
} from '@upbrains/shared';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class IgniteService {
  constructor(private http: HttpClient) {}

  postInboxAnalysis(
    request: PostInboxAnalysisRequest
  ): Observable<InboxAnalysisResponse> {
    return this.http.post<InboxAnalysisResponse>(
      environment.apiUrl + '/ignite/inbox-analysis',
      request
    );
  }
}
