import { createAction, props } from '@ngrx/store';
import { CasesList } from '@upbrains/shared';

const setSelectedCase = createAction(
  '[Cases] Set Selected Case',
  props<{
    selectedCase: CasesList | null;
  }>()
);

const setCasesList = createAction(
  '[Cases] Set Cases List',
  props<{
    casesList: CasesList[];
  }>()
);

export const casesActions = {
  setSelectedCase,
  setCasesList,
};
