import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { CookiesDismissed } from '../../models/cookies-dismissed';
import { ConsentService } from '../../service/consent.service';
import { SessionNames } from '../../utils/session-names';
import { environment } from '../../environments/environment.prod';

//Todo: need refactor
@Component({
  selector: 'ap-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
  @LocalStorage(SessionNames.framerCookiesConsentMode)
  cookies: CookiesDismissed;

  @LocalStorage(SessionNames.framerCookiesDismissed)
  framerCookiesDismissed: boolean;
  customizeBtn = false;
  defaultConsent = {
    ad_storage: 'denied',
    analytics_storage: 'denied',
  };
  constructor(
    private localStorage: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private consentService: ConsentService
  ) {
    if (environment.production) {
      this.localStorage
        .observe(SessionNames.framerCookiesDismissed)
        .subscribe((dismissed) => {
          if (dismissed) {
            this.framerCookiesDismissed = dismissed;
          } else {
            this.framerCookiesDismissed = false;
            this.cdr.detectChanges();
          }
        });
      this.localStorage
        .observe(SessionNames.framerCookiesConsentMode)
        .subscribe((storedCookies) => {
          if (storedCookies) {
            this.cookies = storedCookies;
          } else {
            this.framerCookiesDismissed = false;
            this.cdr.detectChanges();
          }
        });
    } else {
      this.framerCookiesDismissed = true;
    }
  }

  ngOnInit(): void {
    if (!this.cookies && environment.production) {
      this.cookies = new CookiesDismissed();
    }
  }

  acceptAll(): void {
    this.cookies = {
      analytics: true,
      marketing: true,
      necessary: true,
      preferences: true,
    };
    this.framerCookiesDismissed = true;
    this.updateConsentStatus('granted');
  }

  rejectAll(): void {
    this.cookies = {
      analytics: false,
      marketing: false,
      necessary: true,
      preferences: false,
    };
    this.framerCookiesDismissed = true;
    this.updateConsentStatus('denied');
  }
  onCookiesChanged(updatedCookies: CookiesDismissed): void {
    this.cookies = updatedCookies;
    const consentType =
      updatedCookies.analytics || updatedCookies.marketing
        ? 'granted'
        : 'denied';
    this.updateConsentStatus(consentType);
    this.framerCookiesDismissed = true;
    console.log('Updated Cookies:', this.cookies);
    this.customizeBtn = false;
  }
  private updateConsentStatus(consentType: 'granted' | 'denied'): void {
    this.defaultConsent = {
      ad_storage: consentType,
      analytics_storage:
        this.cookies.analytics === true || this.cookies.marketing === true
          ? 'granted'
          : 'denied',
    };
    this.consentService.updateConsentStatus(consentType);
  }
}
