import { Static, Type } from '@sinclair/typebox'


export const ConversationIdentifier = Type.Object({
    identifierId: Type.String(),
    identifierName: Type.String(),
})


export type ConversationIdentifier = Static<typeof ConversationIdentifier>

