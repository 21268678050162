import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private readonly API_URL = `${environment.apiUrl}/remote-files`; // Replace with your API URL

  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  writeRemoteFile({ url }: { url: string }): Observable<string> {
    const token = this.auth.getToken();

    const formData = new FormData();
    formData.append('fileName', '');
    formData.append('url', url);
    formData.append(
      'header',
      JSON.stringify({ Authorization: `Bearer ${token}` })
    );

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .post<{ url: string }>(this.API_URL, formData, { headers })
      .pipe(
        // Transform the response to return only the `url` string
        map((response) => response.url),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error?.message || 'Failed to store entry';
          return throwError(() => new Error(errorMessage));
        })
      );
  }
}
