import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { TemplatesService } from '../../../service';
import { CreateWorkflowTemplateRequest, FlowTemplate, TemplateType } from '@upbrains/shared';
import { TEnumStatic } from '@sinclair/typebox';

@Component({
  selector: 'ap-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTemplateDialogComponent {
  form: FormGroup<{
    name: FormControl<string | null>;
    description: FormControl<string | null>;
  }>;
  createTemplate$?: Observable<FlowTemplate>;
  selected = 'PLATFORM';
  error?:string;
  constructor(
    private dialogRef: MatDialogRef<CreateTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateWorkflowTemplateRequest,
    private fb: FormBuilder,
    private templatesService: TemplatesService,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      name: new FormControl<string | null>(''),
      description: new FormControl<string | null>(''),
    });
  }

  submit() {
    this.error = undefined;
    if (this.form.valid && !this.createTemplate$) {
      const { name, description } = this.form.value;
      const template: CreateWorkflowTemplateRequest = {
        ...this.data,
        type:this.selected as TEnumStatic<typeof TemplateType>,
        description: description as string,
        template: {
          ...this.data.template,
          displayName: name as string,
        },
      };
      this.createTemplate$ = this.templatesService.create(template);
      this.createTemplate$.subscribe({
        next: () => {
          this.dialogRef.close(); // Use the public close method to close the dialog
        },
        error: (err) => {
          this.createTemplate$ = undefined;
          this.error = "You don't have permission to create template " + this.selected;
          this.cdr.detectChanges();
          console.error('Error creating template:', err);
        },
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
